import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import ApiService from '@/core/services/ApiService';
import JwtService from '@/core/services/JwtService';
import { UserInterface, UserAuthInfo } from '@/interfaces/User';
import { credentials } from '@/views/register/_form/_type';
import { getStatus } from '@/core/helpers/fn';

@Module
export default class AuthModule extends VuexModule implements UserAuthInfo {
    user = window.localStorage.getItem('user')
        ? JSON.parse(window.localStorage.getItem('user') as string)
        : null;
    isAuthenticated = !!JwtService.getToken();

    /**
     * Get authenticated user
     * @returns object
     */
    get getUser(): UserInterface {
        return this.user;
    }

    /**
     * Get authenticated data
     * @returns object
     */
    get getAuth(): UserAuthInfo {
        return {
            isAuthenticated: this.isAuthenticated,
            user: this.user
        };
    }

    @Mutation
    [Mutations.SET_TOKEN](payload: { token: string; expires: number }): void {
        JwtService.setToken(payload);
    }

    @Mutation
    [Mutations.REMOVE_TOKEN](): void {
        JwtService.destroyToken();
    }

    @Mutation
    [Mutations.SET_INFO](payload: string): void {
        window.localStorage.setItem('user', payload);
    }

    @Mutation
    [Mutations.REMOVE_INFO](): void {
        window.localStorage.removeItem('user');
    }

    @Mutation
    [Mutations.REMOVE_LAYOUT](): void {
        window.localStorage.removeItem('layout');
    }

    @Mutation
    [Mutations.RESET_AUTH_STATE](): void {
        this.user = null;
        this.isAuthenticated = false;
    }

    @Action({ rawError: true })
    [Actions.LOGIN](
        credentials: {
            email: string;
            password: string;
        },
        remember = false
    ): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            ApiService.post(`/login`, { ...credentials, remember })
                .then(({ data }) => {
                    Promise.all([
                        this.context.commit(Mutations.SET_TOKEN, {
                            token: data.access_token,
                            expires: data.expires_in
                        }),
                        this.context.commit(
                            Mutations.SET_INFO,
                            JSON.stringify(data.user)
                        ),
                        this.context.commit(
                            Mutations.SET_LAYOUT,
                            getStatus(data.user.status) === 'approved' &&
                                data.user.roles &&
                                data.user.roles.length
                                ? data.user.roles[0]
                                : 'guest'
                        )
                    ]).finally(() => {
                        ApiService.setHeader();
                        resolve(data);
                    });
                })
                .catch((e) => {
                    reject(e);
                });
        });
    }

    @Action({ rawError: true })
    [Actions.LOGIN_GOOGLE](code: string): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            ApiService.query('/login/google', {
                params: { code }
            })
                .then(({ data }) => {
                    Promise.all([
                        this.context.commit(Mutations.SET_TOKEN, {
                            token: data.access_token,
                            expires: data.expires_in
                        }),
                        this.context.commit(
                            Mutations.SET_INFO,
                            JSON.stringify(data.user)
                        ),
                        this.context.commit(
                            Mutations.SET_LAYOUT,
                            getStatus(data.user.status) === 'approved' &&
                                data.user.roles &&
                                data.user.roles.length
                                ? data.user.roles[0]
                                : 'guest'
                        )
                    ]).finally(() => {
                        ApiService.setHeader();
                        resolve(data);
                    });
                })
                .catch((e) => {
                    reject(e);
                });
        });
    }

    @Action({ rawError: true })
    [Actions.SIGN_UP](payload: credentials): Promise<void> {
        return new Promise((resolve, reject) => {
            const data = payload;
            delete data.confirm_password;

            ApiService.post('/register', data)
                .then(({ data }) => {
                    Promise.all([
                        this.context.commit(Mutations.SET_TOKEN, {
                            token: data.access_token
                        }),
                        this.context.commit(
                            Mutations.SET_INFO,
                            JSON.stringify(data.user)
                        )
                    ]).finally(() => {
                        resolve(data);
                    });
                })
                .catch((e) => {
                    reject(e);
                });
        });
    }

    @Action({ rawError: true })
    [Actions.RESEND_VERIFICATION_EMAIL](): Promise<void> {
        return new Promise((resolve, reject) => {
            ApiService.post('/email/verify/resend')
                .then(({ data }) => {
                    resolve(data);
                })
                .catch((e) => {
                    reject(e);
                });
        });
    }

    @Action
    [Actions.LOGOUT](): void {
        this.context.commit(Mutations.REMOVE_TOKEN);
        this.context.commit(Mutations.REMOVE_INFO);
        this.context.commit(Mutations.REMOVE_LAYOUT);
        this.context.commit(Mutations.RESET_AUTH_STATE);
    }
}
