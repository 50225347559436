import { createStore } from 'vuex';
import { config } from 'vuex-module-decorators';
import { Actions, Mutations } from './enums/StoreEnums';

import AuthModule from './modules/AuthModule';
import BreadcrumbsModule from './modules/BreadcrumbsModule';
import LayoutModule from './modules/LayoutModule';
import UserModule from './modules/UserModule';
import ApprovalModule from './modules/ApprovalModule';
import CampusModule from './modules/CampusModule';
import CompanyModule from './modules/CompanyModule';
import RoleModule from './modules/RoleModule';
import TeamModule from './modules/TeamModule';
import MyTeamModule from './modules/MyTeamModule';
import ClassModule from './modules/ClassModule';
import ProjectModule from './modules/ProjectModule';
import ProposalModule from './modules/ProposalModule';
import QuestionnaireModule from './modules/QuestionnaireModule';

config.rawError = true;

export default createStore({
    state: {
        locale: window.localStorage.getItem('locale') || 'en-US'
    },
    getters: {
        getLocale(state) {
            return state.locale;
        }
    },
    mutations: {
        [Mutations.SET_LOCALE](state, value) {
            state.locale = value;
            window.localStorage.setItem('locale', value);
        }
    },
    actions: {
        [Actions.UPDATE_LOCALE]({ commit }, locale) {
            commit(Mutations.SET_LOCALE, locale);
        }
    },
    modules: {
        AuthModule,
        BreadcrumbsModule,
        LayoutModule,
        UserModule,
        ApprovalModule,
        CampusModule,
        CompanyModule,
        RoleModule,
        TeamModule,
        MyTeamModule,
        ClassModule,
        ProjectModule,
        ProposalModule,
        QuestionnaireModule
    }
});
