<template>
    <div class="row w-100 min-vh-100 gap-0 m-0">
        <div class="d-none d-lg-block col-6 p-0">
            <div
                class="login-bg min-vh-100 h-100 w-100"
                :style="{
                    backgroundImage: `url(${require('@/assets/images/register.png')})`
                }"
            ></div>
        </div>

        <div class="col-12 col-lg-6 p-0">
            <div
                class="d-flex flex-column w-100 h-100"
                :style="{
                    padding: '4.5rem',
                    maxWidth: '640px',
                    margin: '0 auto'
                }"
            >
                <transition name="fade" mode="out-in">
                    <template v-if="isSuccess">
                        <div
                            class="d-flex justify-content-center align-items-center h-100"
                        >
                            <StepTwo :email="data.email" />
                        </div>
                    </template>
                    <template v-else>
                        <StepOne
                            :is-loading="isLoading"
                            @onSubmit="handleOnSubmit"
                        />
                    </template>
                </transition>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import {
    defineComponent,
    ref,
    inject,
    onMounted,
    ComputedRef,
    computed
} from 'vue';
import { useStore } from 'vuex';
import { Registration as registerFormRules } from '@/core/helpers/validation';

import { credentials } from './_form/_type';
import { Actions } from '@/store/enums/StoreEnums';

import StepOne from './_form/StepOne.vue';
import StepTwo from './_form/StepTwo.vue';
import { UserAuthInfo } from '@/interfaces/User';

export default defineComponent({
    name: 'RegisterPage',
    components: { StepOne, StepTwo },
    setup() {
        const app: any = inject('app');
        const store = useStore();
        const $toast: any = inject('$toast');
        const auth: ComputedRef<UserAuthInfo> = computed(
            () => store.getters['getAuth']
        );
        const data = ref<credentials>({
            first_name: '',
            last_name: '',
            email: '',
            password: '',
            confirm_password: ''
        });
        const isLoading = ref(false);
        const isSuccess = ref(false);

        const handleOnSubmit = async (payload: credentials) => {
            data.value = payload;
            isLoading.value = true;

            try {
                await store.dispatch(Actions.SIGN_UP, payload);
                isSuccess.value = true;
            } catch (e) {
                $toast.error(`Terjadi Kesalahan, ${e}`);
            } finally {
                isLoading.value = false;
            }
        };

        onMounted(() => {
            if (
                auth.value.isAuthenticated &&
                !auth.value.user?.email_verified_at
            )
                isSuccess.value = true;
        });

        return {
            data,
            registerFormRules,
            isLoading,
            isSuccess,
            handleOnSubmit
        };
    }
});
</script>

<style lang="scss" scoped>
.login-bg {
    z-index: -1;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: rgba(0, 0, 0, 0.5);
}
</style>
