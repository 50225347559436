import { RoleInterface } from '@/interfaces/Role';
import { UserInterface } from '@/interfaces/User';

/* eslint-disable @typescript-eslint/no-explicit-any */
export const debounce = (func: any, timeout = 2000) => {
    let timer: any;
    return (...args: any[]) => {
        clearTimeout(timer);
        timer = setTimeout(() => {
            func.apply(this, args);
        }, timeout);
    };
};

export const getStatus = (status: number) => {
    if (status === -1) return 'blocked';
    if (status === 0) return 'pending';
    if (status === 1) return 'approved';
    if (status === 2) return 'disapproved';

    return 'uncomplete';
};

export const isAccountDoesntHavePassword = (user: UserInterface) => {
    if (user.google_id && !user.email_verified_at) return true;
    return false;
};

export const isAccountDoesntHaveCampusData = (user: UserInterface) => {
    const isValid = [!!user.university, !!user.faculty, !!user.nim];

    if (isValid.includes(false)) return true;
    return false;
};

export const getRoleAccessCode = (roles: RoleInterface[], role_id: number) => {
    const role = roles.find((role) => role.id === role_id);

    if (role) return role.access_code;
    return null;
};

export const entryYearOptions = () => {
    const currentYear = new Date().getFullYear();
    const years = [];
    let startYear = 1980;

    while (startYear <= currentYear) {
        years.push(startYear++);
    }

    return years;
};

export const classOptions = () => {
    return Array.from({ length: 20 }, (_, i) => i + 1).map((myNumber) => {
        return myNumber.toLocaleString('en-US', {
            minimumIntegerDigits: 2,
            useGrouping: false
        });
    });
};

export const projectStatus = (status: number) => {
    switch (status) {
        case 1:
            return 'Initiation';
        case 2:
            return 'Planning';
        case 3:
            return 'Execution & Monitoring';
        case 4:
            return 'Closure';
        default:
            return 'Draft';
    }
};

export const phaseStatus = (status: number) => {
    switch (status) {
        case 1:
            return 'On Review';
        case 2:
            return 'Approved';
        case -1:
            return 'Rejected';
        default:
            return 'On Progress';
    }
};

export default {
    debounce,
    getStatus,
    isAccountDoesntHavePassword,
    isAccountDoesntHaveCampusData,
    getRoleAccessCode,
    entryYearOptions,
    projectStatus
};
