<template>
    <main>
        <section
            ref="hero"
            class="hero d-flex justify-content-center align-items-center min-vh-100 text-white"
            :style="{
                backgroundImage: `url(${require('@/assets/images/hero.png')})`
            }"
        >
            <div
                class="position-relative col-10 col-md-8 col-lg-6 text-center"
                style="z-index: 1"
            >
                <h3 class="fs-1 fw-bold mb-3">
                    {{ $t('pjbl.title') }}
                </h3>

                <p class="mb-4 fs-6 fw-light">
                    {{ $t('pjbl.description') }}
                </p>

                <el-button type="primary" size="large">
                    {{ $t('seeMore') }}
                </el-button>
            </div>
        </section>

        <section ref="features" class="my-5 mx-5 p-4">
            <h4
                class="fs-3 fw-bold text-center text-md-start"
                style="margin-bottom: 72px"
            >
                {{ $tc('feature', 2) }}
            </h4>

            <div class="row gap-5 gap-sm-0">
                <div class="col-12 mb-sm-5 col-md-6 col-lg-4 col-xl">
                    <el-card
                        class="h-100 position-relative overflow-visible px-0"
                        shadow="false"
                    >
                        <el-avatar
                            class="feature-icon bg-danger-light"
                            :size="70"
                        >
                            <img
                                class="p-3"
                                :src="cardIcons['list']"
                                alt="list-icon"
                            />
                        </el-avatar>

                        <div class="mt-4 text-center">
                            <p class="fs-5 text-uppercase fw-bold">
                                {{ $t('class.title') }}
                            </p>
                            <p class="text-secondary">
                                {{ $t('class.description') }}
                            </p>
                        </div>
                    </el-card>
                </div>

                <div class="col-12 mb-sm-5 col-md-6 col-lg-4 col-xl">
                    <el-card
                        class="h-100 position-relative overflow-visible px-0"
                        shadow="false"
                    >
                        <el-avatar
                            class="feature-icon bg-warning-light"
                            :size="70"
                        >
                            <img
                                class="p-3"
                                :src="cardIcons['users']"
                                alt="list-icon"
                            />
                        </el-avatar>

                        <div class="mt-4 text-center">
                            <p class="fs-5 text-uppercase fw-bold">
                                {{ $t('team.title') }}
                            </p>
                            <p class="text-secondary">
                                {{ $t('team.description') }}
                            </p>
                        </div>
                    </el-card>
                </div>

                <div class="col-12 mb-sm-5 col-md-6 col-lg-4 col-xl">
                    <el-card
                        class="h-100 position-relative overflow-visible px-0"
                        shadow="false"
                    >
                        <el-avatar
                            class="feature-icon bg-info-light"
                            :size="70"
                        >
                            <img
                                class="p-3"
                                :src="cardIcons['documentMail']"
                                alt="list-icon"
                            />
                        </el-avatar>

                        <div class="mt-4 text-center">
                            <p class="fs-5 text-uppercase fw-bold">
                                {{ $t('proposal.title') }}
                            </p>
                            <p class="text-secondary">
                                {{ $t('proposal.description') }}
                            </p>
                        </div>
                    </el-card>
                </div>

                <div class="col-12 mb-sm-5 col-md-6 col-lg-4 col-xl">
                    <el-card
                        class="h-100 position-relative overflow-visible px-0"
                        shadow="false"
                    >
                        <el-avatar
                            class="feature-icon bg-success-light"
                            :size="70"
                        >
                            <img
                                class="p-3"
                                :src="cardIcons['badge']"
                                alt="list-icon"
                            />
                        </el-avatar>

                        <div class="mt-4 text-center">
                            <p class="fs-5 text-uppercase fw-bold">
                                {{ $t('project.title') }}
                            </p>
                            <p class="text-secondary">
                                {{ $t('project.description') }}
                            </p>
                        </div>
                    </el-card>
                </div>

                <div class="col-12 mb-sm-5 col-md-6 col-lg-4 col-xl">
                    <el-card
                        class="h-100 position-relative overflow-visible px-0"
                        shadow="false"
                    >
                        <el-avatar
                            class="feature-icon bg-purple-light"
                            :size="70"
                        >
                            <img
                                class="p-3"
                                :src="cardIcons['pencil']"
                                alt="list-icon"
                            />
                        </el-avatar>

                        <div class="mt-4 text-center">
                            <p class="fs-5 text-uppercase fw-bold">
                                {{ $t('questionnaire.title') }}
                            </p>
                            <p class="text-secondary">
                                {{ $t('questionnaire.description') }}
                            </p>
                        </div>
                    </el-card>
                </div>
            </div>
        </section>

        <section
            ref="contactUs"
            class="position-relative p-4"
            style="margin: 8rem 3rem"
        >
            <div class="row align-items-center">
                <div class="d-none d-lg-block col-12 col-lg-6">
                    <div
                        class="contact-us rounded-pill"
                        :style="{
                            backgroundImage: `url(${require('@/assets/images/contact-us.png')})`
                        }"
                    ></div>

                    <div class="position-relative" style="height: 700px">
                        <div
                            class="position-absolute p-5 bg-primary rounded-circle"
                            :style="{
                                top: '100px',
                                left: '280px'
                            }"
                        ></div>
                    </div>
                </div>
                <div class="col-12 col-lg-6">
                    <h4 class="fs-3 fw-bold text-center text-md-start mb-4">
                        {{ $t('doYouHaveAProblem') }} {{ $t('please') }}
                        <span
                            class="text-lowercase"
                            v-text="$t('contactUs')"
                        ></span>
                    </h4>

                    <el-form
                        :model="contactForm"
                        ref="loginForm"
                        class="form mb-4"
                        :rules="contactUsFormRules"
                        @submit.prevent="onSubmitContactUs"
                    >
                        <el-form-item prop="name" class="mb-3" required>
                            <label
                                class="mb-1"
                                v-text="$t('input.name.label')"
                            ></label>
                            <el-input
                                v-model="contactForm.name"
                                type="text"
                                :placeholder="$t('input.name.placeholder')"
                                size="large"
                            ></el-input>
                        </el-form-item>

                        <el-form-item prop="email" class="mb-3" required>
                            <label
                                class="mb-1"
                                v-text="$t('input.email.label')"
                            ></label>
                            <el-input
                                v-model="contactForm.email"
                                :placeholder="$t('input.email.placeholder')"
                                size="large"
                            >
                            </el-input>
                        </el-form-item>

                        <el-form-item prop="subject" class="mb-3" required>
                            <label
                                class="mb-1"
                                v-text="$t('input.subject.label')"
                            ></label>
                            <el-input
                                v-model="contactForm.subject"
                                :placeholder="$t('input.subject.placeholder')"
                                size="large"
                            >
                            </el-input>
                        </el-form-item>

                        <el-form-item prop="message" class="mb-4" required>
                            <label
                                class="mb-1"
                                v-text="$t('input.message.label')"
                            ></label>
                            <el-input
                                v-model="contactForm.message"
                                :placeholder="$t('input.message.placeholder')"
                                type="textarea"
                                :rows="4"
                            >
                            </el-input>
                        </el-form-item>

                        <div class="d-block d-lg-inline-block">
                            <el-button
                                class="w-100"
                                size="large"
                                type="primary"
                                native-type="submit"
                                v-loading.fullscreen.lock="isLoading.contactUs"
                            >
                                {{ $t('send') }} {{ $t('message') }}
                            </el-button>
                        </div>
                    </el-form>
                </div>
            </div>
        </section>
    </main>
</template>

<script lang="ts">
import { defineComponent, ref, inject, onMounted, onUpdated } from 'vue';

import listIcon from '@/assets/icons/list.svg';
import usersIcon from '@/assets/icons/users.svg';
import documentMailIcon from '@/assets/icons/document-mail.svg';
import badgeIcon from '@/assets/icons/badge.svg';
import pencilIcon from '@/assets/icons/pencil.svg';

import { ContactUs as contactUsFormRules } from '@/core/helpers/validation';
import { useRoute } from 'vue-router';

export default defineComponent({
    name: 'LandingPage',
    setup() {
        const app: any = inject('app');
        const $toast: any = inject('$toast');
        const route = useRoute();
        const hero = ref();
        const features = ref();
        const contactUs = ref();
        const contactForm = ref({
            name: '',
            email: '',
            subject: '',
            message: ''
        });
        const isLoading = ref({
            contactUs: false
        });
        const cardIcons = ref({
            list: listIcon,
            users: usersIcon,
            documentMail: documentMailIcon,
            badge: badgeIcon,
            pencil: pencilIcon
        });

        const scrollTo = (el: string) => {
            const bodyRect = document.body.getBoundingClientRect().top;

            if (el === '#') hero.value.scrollIntoView({ behavior: 'smooth' });
            else if (el === '#features') {
                const offset = 100;
                const elementRect = features.value.getBoundingClientRect().top;
                const elementPosition = elementRect - bodyRect;
                const offsetPosition = elementPosition - offset;

                window.scrollTo({
                    top: offsetPosition,
                    behavior: 'smooth'
                });
            } else if (el === '#contact-us') {
                const offset = 40;
                const elementRect = contactUs.value.getBoundingClientRect().top;
                const elementPosition = elementRect - bodyRect;
                const offsetPosition = elementPosition - offset;

                window.scrollTo({
                    top: offsetPosition,
                    behavior: 'smooth'
                });
            }
        };

        const onSubmitContactUs = () => {
            //
        };

        onMounted(() => {
            const hash = route.hash;
            scrollTo(hash);
        });

        onUpdated(() => {
            const hash = route.hash;
            scrollTo(hash);
        });

        return {
            hero,
            features,
            contactUs,
            cardIcons,
            contactForm,
            contactUsFormRules,
            isLoading,
            onSubmitContactUs
        };
    }
});
</script>

<style lang="scss" scoped>
.hero {
    z-index: -1;
    background-repeat: no-repeat;
    background-size: cover;

    &::before {
        content: '';
        position: absolute;
        z-index: 0;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.8);
    }
}
.contact-us {
    position: absolute;
    top: 50%;
    left: -400px;
    transform: translate(0%, -50%);
    background-repeat: no-repeat;
    background-size: contain;
    background-position-x: right;
    background-position-y: 20%;
    height: 700px;
    width: 800px;
}
</style>
