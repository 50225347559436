import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { RoleInterface } from '@/interfaces/Role';
import LayoutService, { LayoutConfig } from '@/core/services/LayoutService';

export interface PathBasedMenu {
    name: string;
    path: string;
    hidden?: boolean;
    exact?: boolean;
    icon?: string;
    disabled?: boolean;
}

interface LayoutInfo {
    role: RoleInterface;
    layout: LayoutConfig;
}

@Module
export default class LayoutModule extends VuexModule implements LayoutInfo {
    role = window.localStorage.getItem('user')
        ? JSON.parse(window.localStorage.getItem('user') as string).roles
              .role_name
        : 'user';
    layout =
        window.localStorage.getItem('layout') &&
        window.localStorage.getItem('layout') !== 'undefined'
            ? JSON.parse(window.localStorage.getItem('layout') as string)
            : {
                  sidebar: null
              };

    /**
     * Get Layout
     * @returns object
     */
    get getLayout(): LayoutConfig {
        return this.layout;
    }

    @Mutation
    [Mutations.SET_LAYOUT](role: RoleInterface | 'guest'): void {
        LayoutService.init(role);
    }

    @Action({ rawError: true })
    [Actions.UPDATE_LAYOUT](role: RoleInterface | 'guest'): void {
        this.context.commit(Mutations.SET_LAYOUT, role);
    }
}
