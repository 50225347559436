import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { PaginationInterface } from '@/interfaces/Pagination';
import { initialPagination } from '@/core/helpers/default-pagination';
import ApiService from '@/core/services/ApiService';
import { ProposalInterface } from '@/interfaces/Proposal';

interface ProposalInfo {
    proposalList: {
        data: ProposalInterface[];
        meta: PaginationInterface;
    };
    proposalApprovalList: {
        data: ProposalInterface[];
        meta: PaginationInterface;
    };
}

@Module
export default class ProposalModule extends VuexModule implements ProposalInfo {
    proposalList: { data: ProposalInterface[]; meta: PaginationInterface } = {
        data: [],
        meta: initialPagination
    };
    proposalApprovalList: {
        data: ProposalInterface[];
        meta: PaginationInterface;
    } = {
        data: [],
        meta: initialPagination
    };

    /**
     * Get All Proposals
     * @returns object
     */
    get getProposals(): {
        data: ProposalInterface[];
        meta: PaginationInterface;
    } {
        return {
            data: this.proposalList.data,
            meta: this.proposalList.meta
        };
    }

    /**
     * Get All Approval Proposals
     * @returns object
     */
    get getApprovalProposals(): {
        data: ProposalInterface[];
        meta: PaginationInterface;
    } {
        return {
            data: this.proposalApprovalList.data,
            meta: this.proposalApprovalList.meta
        };
    }

    @Mutation
    [Mutations.SET_PROPOSALS](payload: {
        data: ProposalInterface[];
        meta: PaginationInterface;
    }): void {
        this.proposalList.data = payload.data;
        this.proposalList.meta = payload.meta;
    }

    @Mutation
    [Mutations.SET_PROPOSALS_APPROVAL](payload: {
        data: ProposalInterface[];
        meta: PaginationInterface;
    }): void {
        this.proposalApprovalList.data = payload.data;
        this.proposalApprovalList.meta = payload.meta;
    }

    @Mutation
    [Mutations.SET_PROPOSAL](payload: any): void {
        this.proposalList.data = this.proposalList.data.map((team) =>
            payload.id === team.id ? { ...team, ...payload } : team
        );
    }

    @Mutation
    [Mutations.REMOVE_PROPOSAL](id: number): void {
        this.proposalList.data = this.proposalList.data.filter(
            (team) => team.id !== id
        );
        this.proposalList.meta.count -= 1;
    }

    @Action({ rawError: true })
    [Actions.GET_PROPOSAL_LIST](query?: any): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            ApiService.get(`/proposals`, query)
                .then(({ data }) => {
                    this.context.commit(Mutations.SET_PROPOSALS, data.data);
                    resolve(data.data);
                })
                .catch((e) => {
                    reject(e);
                });
        });
    }

    @Action({ rawError: true })
    [Actions.GET_PROPOSAL_APPROVAL_LIST](query?: any): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            ApiService.get(`/proposals/approval`, query)
                .then(({ data }) => {
                    this.context.commit(
                        Mutations.SET_PROPOSALS_APPROVAL,
                        data.data
                    );
                    resolve(data.data);
                })
                .catch((e) => {
                    reject(e);
                });
        });
    }

    @Action({ rawError: true })
    [Actions.UPDATE_PROPOSAL](payload: {
        data: ProposalInterface[];
        team_id: number;
    }): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            ApiService.patch(`/proposals/${payload.team_id}`, payload.data)
                .then(({ data }) => {
                    this.context.commit(Mutations.SET_PROPOSAL, data.data);
                    resolve(data.data);
                })
                .catch((e) => {
                    reject(e);
                });
        });
    }
}
