import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { PaginationInterface } from '@/interfaces/Pagination';
import { initialPagination } from '@/core/helpers/default-pagination';
import ApiService from '@/core/services/ApiService';
import { ClassInterface } from '@/interfaces/Class';

interface ClassInfo {
    myClass: {
        data: ClassInterface[];
        meta: PaginationInterface;
    };
    class: {
        data: ClassInterface[];
        meta: PaginationInterface;
    };
}

@Module
export default class ClassModule extends VuexModule implements ClassInfo {
    myClass: { data: ClassInterface[]; meta: PaginationInterface } = {
        data: [],
        meta: initialPagination
    };
    class: { data: ClassInterface[]; meta: PaginationInterface } = {
        data: [],
        meta: initialPagination
    };

    /**
     * Get All User Class
     * @returns object
     */
    get getMyClass(): {
        data: ClassInterface[];
        meta: PaginationInterface;
    } {
        return {
            data: this.myClass.data,
            meta: this.myClass.meta
        };
    }

    /**
     * Get All User Class
     * @returns object
     */
    get getClass(): {
        data: ClassInterface[];
        meta: PaginationInterface;
    } {
        return {
            data: this.class.data,
            meta: this.class.meta
        };
    }

    @Mutation
    [Mutations.SET_MY_CLASSES](payload: {
        data: ClassInterface[];
        meta: PaginationInterface;
    }): void {
        this.myClass.data = payload.data;
        this.myClass.meta = payload.meta;
    }

    @Mutation
    [Mutations.SET_CLASSES](payload: {
        data: ClassInterface[];
        meta: PaginationInterface;
    }): void {
        this.class.data = payload.data;
        this.class.meta = payload.meta;
    }

    @Mutation
    [Mutations.SET_MY_CLASS](payload: ClassInterface): void {
        this.myClass.data = this.myClass.data.map((v) =>
            payload.id === v.id ? { ...v, ...payload } : v
        );
    }

    @Mutation
    [Mutations.REMOVE_MY_CLASS](id: number): void {
        this.myClass.data = this.myClass.data.filter((v) => v.id !== id);
        this.myClass.meta.count -= 1;
    }

    @Action({ rawError: true })
    [Actions.GET_MY_CLASS_LIST](query?: any): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            ApiService.get(`/my-class`, query)
                .then(({ data }) => {
                    this.context.commit(Mutations.SET_MY_CLASSES, data.data);
                    resolve(data.data);
                })
                .catch((e) => {
                    reject(e);
                });
        });
    }

    @Action({ rawError: true })
    [Actions.GET_CLASS_LIST](query?: any): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            ApiService.get(`/class`, query)
                .then(({ data }) => {
                    this.context.commit(Mutations.SET_CLASSES, data.data);
                    resolve(data.data);
                })
                .catch((e) => {
                    reject(e);
                });
        });
    }

    @Action({ rawError: true })
    [Actions.UPDATE_MY_CLASS](payload: {
        data: ClassInterface;
        class_id: number;
    }): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            ApiService.patch(`/class/${payload.class_id}`, payload.data)
                .then(({ data }) => {
                    this.context.commit(Mutations.SET_MY_CLASS, data.data);
                    resolve(data.data);
                })
                .catch((e) => {
                    reject(e);
                });
        });
    }
}
