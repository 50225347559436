import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { PaginationInterface } from '@/interfaces/Pagination';
import { initialPagination } from '@/core/helpers/default-pagination';
import ApiService from '@/core/services/ApiService';
import { TeamInterface } from '@/interfaces/Team';
import { Team } from '@/views/dashboard/teams/_form/_type';

interface TeamInfo {
    data: TeamInterface[];
    meta: PaginationInterface;
}

@Module
export default class TeamModule extends VuexModule implements TeamInfo {
    data: TeamInterface[] = [];
    meta = initialPagination;

    /**
     * Get All Teams
     * @returns object
     */
    get getTeams(): {
        data: TeamInterface[];
        meta: PaginationInterface;
    } {
        return {
            data: this.data,
            meta: this.meta
        };
    }

    @Mutation
    [Mutations.SET_TEAMS](payload: {
        data: TeamInterface[];
        meta: PaginationInterface;
    }): void {
        this.data = payload.data;
        this.meta = payload.meta;
    }

    @Mutation
    [Mutations.SET_TEAM](payload: TeamInterface): void {
        this.data = this.data.map((team) =>
            payload.id === team.id ? { ...team, ...payload } : team
        );
    }

    @Mutation
    [Mutations.REMOVE_TEAM](id: number): void {
        this.data = this.data.filter((team) => team.id !== id);
        this.meta.count -= 1;
    }

    @Action({ rawError: true })
    [Actions.GET_TEAM_LIST](query?: any): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            ApiService.get(`/teams`, query)
                .then(({ data }) => {
                    this.context.commit(Mutations.SET_TEAMS, data.data);
                    resolve(data.data);
                })
                .catch((e) => {
                    reject(e);
                });
        });
    }

    @Action({ rawError: true })
    [Actions.CREATE_TEAM](payload: Team): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            ApiService.post(`/teams`, payload)
                .then(({ data }) => {
                    this.context.commit(Mutations.ADD_TEAM, data.data);
                    resolve(data.data);
                })
                .catch((e) => {
                    reject(e);
                });
        });
    }

    @Action({ rawError: true })
    [Actions.UPDATE_TEAM](payload: {
        data: TeamInterface;
        team_id: number;
    }): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            ApiService.patch(`/teams/${payload.team_id}`, payload.data)
                .then(({ data }) => {
                    this.context.commit(Mutations.SET_TEAM, data.data);
                    resolve(data.data);
                })
                .catch((e) => {
                    reject(e);
                });
        });
    }

    @Action({ rawError: true })
    [Actions.DELETE_TEAM](id: number): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            ApiService.delete(`/teams/${id}`)
                .then(({ data }) => {
                    this.context.commit(Mutations.REMOVE_TEAM, id);
                    resolve(data.data);
                })
                .catch((e) => {
                    reject(e);
                });
        });
    }
}
