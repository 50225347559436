import { App } from 'vue';
import axios, { AxiosResponse, AxiosRequestConfig } from 'axios';
import store from '@/store';
import router from '@/router';
import VueAxios from 'vue-axios';
import JwtService, { getToken, setToken } from '@/core/services/JwtService';
import { Actions } from '@/store/enums/StoreEnums';

/**
 * @description service to call HTTP request via Axios
 */
class ApiService {
    /**
     * @description property to share vue instance
     */
    public static vueInstance: App;

    /**
     * @description initialize vue axios
     */
    public static init(app: App<Element>) {
        ApiService.vueInstance = app;
        ApiService.vueInstance.use(VueAxios, axios);
        ApiService.vueInstance.axios.defaults.baseURL =
            process.env.VUE_APP_API_URL;
        // this.setHeader();
    }

    /**
     * @description set the default HTTP request headers
     */
    public static setHeader(): void {
        ApiService.vueInstance.axios.defaults.headers.common[
            'Authorization'
        ] = `Bearer ${JwtService.getToken()}`;
    }

    /**
     * @description refresh token if still valid
     */
    private static refreshToken(requestCallback: Promise<AxiosResponse>): void {
        const accessToken = getToken();

        if (accessToken) {
            ApiService.vueInstance.axios.post('/refresh').then(({ data }) => {
                setToken({ token: data.accessToken });
                this.setHeader();
                requestCallback;
            });
        } else {
            store.dispatch(Actions.LOGOUT).then(() => {
                console.error('session anda telah berakhir');
                router.push({ name: 'login' });
            });
        }
    }

    /**
     * @description send the GET HTTP request
     * @param resource: string
     * @param params: AxiosRequestConfig
     * @returns Promise<AxiosResponse>
     */
    public static query(
        resource: string,
        params: AxiosRequestConfig
    ): Promise<AxiosResponse> {
        // store.dispatch(Actions.ADD_BODY_CLASSNAME, "page-loading");
        const request = ApiService.vueInstance.axios
            .get(resource, params)
            .catch((err) => {
                if (err.response?.status === 401) {
                    this.refreshToken(request);
                }
                const error = err.response && err.response.data.message;
                return Promise.reject(error);
            });

        return request;
    }

    /**
     * @description send the GET HTTP request
     * @param resource: string
     * @param slug: string
     * @returns Promise<AxiosResponse>
     */
    public static get(resource: string, params?: any): Promise<AxiosResponse> {
        // store.dispatch(Actions.ADD_BODY_CLASSNAME, "page-loading");
        const request = ApiService.vueInstance.axios
            .get(`${resource}`, {
                params
            })
            .catch((err) => {
                if (err.response?.status === 401) {
                    this.refreshToken(request);
                }
                const error = err.response && err.response.data.message;
                return Promise.reject(error);
            });

        return request;
    }

    /**
     * @description set the POST HTTP request
     * @param resource: string
     * @param params: any
     * @param config: AxiosRequestConfig
     * @returns Promise<AxiosResponse>
     */
    public static post(
        resource: string,
        params?: any,
        config?: AxiosRequestConfig | undefined
    ): Promise<AxiosResponse> {
        // store.dispatch(Actions.ADD_BODY_CLASSNAME, "page-loading");
        const request = ApiService.vueInstance.axios
            .post(`${resource}`, params, config)
            .catch((err) => {
                if (err.response?.status === 401) {
                    this.refreshToken(request);
                }
                const error = err.response && err.response.data.message;
                return Promise.reject(error);
            });

        return request;
    }

    /**
     * @description Send the PUT HTTP request
     * @param resource: string
     * @param params: AxiosRequestConfig
     * @returns Promise<AxiosResponse>
     */
    public static put(
        resource: string,
        slug: string,
        params: any,
        config?: AxiosRequestConfig | undefined
    ): Promise<AxiosResponse> {
        // store.dispatch(Actions.ADD_BODY_CLASSNAME, "page-loading");
        const request = ApiService.vueInstance.axios
            .put(`${resource}/${slug}`, params, config)
            .catch((err) => {
                if (err.response?.status === 401) {
                    this.refreshToken(request);
                }
                const error = err.response && err.response.data.message;
                return Promise.reject(error);
            });

        return request;
    }

    /**
     * @description Send the PATCH HTTP request
     * @param resource: string
     * @param params: AxiosRequestConfig
     * @returns Promise<AxiosResponse>
     */
    public static patch(resource: string, data: any): Promise<AxiosResponse> {
        const request = ApiService.vueInstance.axios
            .patch(resource, data)
            .catch((err) => {
                if (err.response?.status === 401) {
                    this.refreshToken(request);
                }
                const error = err.response && err.response.data.message;
                return Promise.reject(error);
            });

        return request;
    }

    /**
     * @description Send the DELETE HTTP request
     * @param resource: string
     * @returns Promise<AxiosResponse>
     */
    public static delete(resource: string): Promise<AxiosResponse> {
        // store.dispatch(Actions.ADD_BODY_CLASSNAME, "page-loading");
        const request = ApiService.vueInstance.axios
            .delete(resource)
            .catch((err) => {
                if (err.response?.status === 401) {
                    this.refreshToken(request);
                }
                const error = err.response && err.response.data.message;
                return Promise.reject(error);
            });

        return request;
    }
}

export default ApiService;
