import { RouteRecordRaw } from 'vue-router';

import BlankLayout from '../layouts/BlankLayout.vue';

import Home from '../views/Home.vue';
import Login from '../views/Login.vue';
import LoginGoogle from '../views/LoginGoogle.vue';
import Register from '../views/register/Register.vue';

export const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'main',
        redirect: {
            name: 'landing-page'
        },
        meta: {
            group: 'main'
        },
        component: () =>
            import(
                /* webpackChunkName: "main-layout" */ '@/layouts/MainLayout.vue'
            ),
        children: [
            {
                path: '',
                name: 'landing-page',
                component: Home
            }
        ]
    },
    {
        path: '/login',
        name: 'login',
        component: Login,
        meta: {
            disableIfAuthenticated: true
        }
    },
    {
        path: '/login/google',
        name: 'login-google',
        component: LoginGoogle,
        meta: {
            disableIfAuthenticated: true
        }
    },
    {
        path: '/register',
        name: 'register',
        component: Register
    },
    {
        path: '/dashboard',
        name: 'dashboard',
        redirect: {
            name: 'dashboard-overview'
        },
        meta: {
            group: 'dashboard',
            requiresAuth: true
        },
        component: () =>
            import(
                /* webpackChunkName: "dashboard" */ '@/layouts/DashboardLayout.vue'
            ),
        children: [
            {
                path: 'overview',
                name: 'dashboard-overview',
                meta: {
                    roleAccess: ['*']
                },
                component: () =>
                    import(
                        /* webpackChunkName: "dashboard-overview" */ '@/views/dashboard/Overview.vue'
                    )
            },
            {
                path: 'verify-data',
                name: 'dashboard-verify-data',
                meta: {
                    roleAccess: ['user', 'lecturer', 'supervisor']
                },
                component: () =>
                    import(
                        /* webpackChunkName: "dashboard-verify-data" */ '@/views/dashboard/verify/VerifyData.vue'
                    )
            },
            {
                path: 'faculties',
                name: 'dashboard-faculties',
                meta: {
                    roleAccess: ['admin']
                },
                component: () =>
                    import(
                        /* webpackChunkName: "dashboard-faculties" */ '@/views/dashboard/faculties/Faculties.vue'
                    )
            },
            {
                path: 'faculties/create',
                name: 'dashboard-faculties-create',
                meta: {
                    roleAccess: ['admin']
                },
                component: () =>
                    import(
                        /* webpackChunkName: "dashboard-faculties-create" */ '@/views/dashboard/faculties/CreateFaculty.vue'
                    )
            },
            {
                path: 'majors',
                name: 'dashboard-majors',
                meta: {
                    roleAccess: ['admin']
                },
                component: () =>
                    import(
                        /* webpackChunkName: "dashboard-majors" */ '@/views/dashboard/majors/Majors.vue'
                    )
            },
            {
                path: 'majors/create',
                name: 'dashboard-majors-create',
                meta: {
                    roleAccess: ['admin']
                },
                component: () =>
                    import(
                        /* webpackChunkName: "dashboard-majors-create" */ '@/views/dashboard/majors/CreateMajor.vue'
                    )
            },
            {
                path: 'companies',
                name: 'dashboard-companies',
                meta: {
                    roleAccess: ['admin']
                },
                component: () =>
                    import(
                        /* webpackChunkName: "dashboard-companies" */ '@/views/dashboard/companies/Companies.vue'
                    )
            },
            {
                path: 'companies/create',
                name: 'dashboard-companies-create',
                meta: {
                    roleAccess: ['admin']
                },
                component: () =>
                    import(
                        /* webpackChunkName: "dashboard-companies-create" */ '@/views/dashboard/companies/CreateCompany.vue'
                    )
            },
            {
                path: 'users',
                name: 'dashboard-users',
                meta: {
                    roleAccess: ['admin']
                },
                component: () =>
                    import(
                        /* webpackChunkName: "dashboard-users" */ '@/views/dashboard/users/Users.vue'
                    )
            },
            {
                path: 'users/approval',
                name: 'dashboard-approval',
                meta: {
                    roleAccess: ['admin']
                },
                component: () =>
                    import(
                        /* webpackChunkName: "dashboard-approval" */ '@/views/dashboard/users/Approval.vue'
                    )
            },
            {
                path: 'users/disapproved',
                name: 'dashboard-disapproved',
                meta: {
                    roleAccess: ['admin']
                },
                component: () =>
                    import(
                        /* webpackChunkName: "dashboard-disapproved" */ '@/views/dashboard/users/Disapproved.vue'
                    )
            },
            {
                path: 'users/blocked',
                name: 'dashboard-blocked',
                meta: {
                    roleAccess: ['admin']
                },
                component: () =>
                    import(
                        /* webpackChunkName: "dashboard-blocked" */ '@/views/dashboard/users/Blocked.vue'
                    )
            },
            {
                path: 'teams',
                name: 'dashboard-teams',
                meta: {
                    roleAccess: ['lecturer']
                },
                component: () =>
                    import(
                        /* webpackChunkName: "dashboard-teams" */ '@/views/dashboard/teams/Teams.vue'
                    )
            },
            {
                path: 'teams/create',
                name: 'dashboard-teams-create',
                meta: {
                    roleAccess: ['lecturer']
                },
                component: () =>
                    import(
                        /* webpackChunkName: "dashboard-teams-create" */ '@/views/dashboard/teams/create/TeamCreate.vue'
                    )
            },
            {
                path: 'my-teams',
                name: 'dashboard-my-teams',
                meta: {
                    roleAccess: ['user']
                },
                component: () =>
                    import(
                        /* webpackChunkName: "dashboard-my-teams" */ '@/views/dashboard/teams/MyTeams.vue'
                    )
            },
            // {
            //     path: 'class',
            //     name: 'dashboard-class',
            //     meta: {
            //         roleAccess: ['lecturer', 'user']
            //     },
            //     component: () =>
            //         import(
            //             /* webpackChunkName: "dashboard-class" */ '@/views/dashboard/class/Class.vue'
            //         )
            // },
            {
                path: 'my-class',
                name: 'dashboard-my-class',
                meta: {
                    roleAccess: ['lecturer', 'user']
                },
                component: () =>
                    import(
                        /* webpackChunkName: "dashboard-my-class" */ '@/views/dashboard/class/MyClass.vue'
                    )
            },
            {
                path: 'class/create',
                name: 'dashboard-class-create',
                meta: {
                    roleAccess: ['lecturer']
                },
                component: () =>
                    import(
                        /* webpackChunkName: "dashboard-class-create" */ '@/views/dashboard/class/create/ClassCreate.vue'
                    )
            },
            {
                path: 'class/enroll',
                name: 'dashboard-class-enroll',
                meta: {
                    roleAccess: ['user']
                },
                component: () =>
                    import(
                        /* webpackChunkName: "dashboard-class-enroll" */ '@/views/dashboard/class/enroll/ClassEnroll.vue'
                    )
            },
            {
                path: 'class/:id',
                name: 'dashboard-class-detail',
                meta: {
                    roleAccess: ['lecturer', 'user']
                },
                component: () =>
                    import(
                        /* webpackChunkName: "dashboard-class-detail" */ '@/views/dashboard/class/show/ClassDetail.vue'
                    )
            },
            {
                path: 'class/:id/lessons/:lessonId',
                name: 'dashboard-class-lesson-module',
                meta: {
                    roleAccess: ['lecturer', 'user']
                },
                component: () =>
                    import(
                        /* webpackChunkName: "dashboard-class-lesson-module" */ '@/views/dashboard/class/module/ModuleDetail.vue'
                    )
            },
            {
                path: 'projects',
                name: 'dashboard-projects',
                meta: {
                    roleAccess: ['user']
                },
                component: () =>
                    import(
                        /* webpackChunkName: "dashboard-projects" */ '@/views/dashboard/projects/Projects.vue'
                    )
            },
            {
                path: 'projects/create',
                name: 'dashboard-projects-create',
                meta: {
                    roleAccess: ['user']
                },
                component: () =>
                    import(
                        /* webpackChunkName: "dashboard-projects-create" */ '@/views/dashboard/projects/create/ProjectCreate.vue'
                    )
            },
            {
                path: 'projects/approval',
                name: 'dashboard-projects-approval',
                meta: {
                    roleAccess: ['lecturer']
                },
                component: () =>
                    import(
                        /* webpackChunkName: "dashboard-projects-approval" */ '@/views/dashboard/projects/ProjectsApproval.vue'
                    )
            },
            {
                path: 'projects/:id',
                name: 'dashboard-projects-detail',
                meta: {
                    roleAccess: ['lecturer', 'supervisor', 'user']
                },
                component: () =>
                    import(
                        /* webpackChunkName: "dashboard-projects-detail" */ '@/views/dashboard/projects/show/ProjectDetail.vue'
                    )
            },
            {
                path: 'projects/:id/update',
                name: 'dashboard-projects-update',
                meta: {
                    roleAccess: ['user']
                },
                component: () =>
                    import(
                        /* webpackChunkName: "dashboard-projects-update" */ '@/views/dashboard/projects/update/ProjectUpdate.vue'
                    )
            },
            {
                path: 'projects/:id/review',
                name: 'dashboard-projects-review',
                meta: {
                    roleAccess: ['lecturer']
                },
                component: () =>
                    import(
                        /* webpackChunkName: "dashboard-projects-review" */ '@/views/dashboard/projects/review/ProjectReview.vue'
                    )
            },
            {
                path: 'proposals',
                name: 'dashboard-proposals',
                meta: {
                    roleAccess: ['user']
                },
                component: () =>
                    import(
                        /* webpackChunkName: "dashboard-proposals" */ '@/views/dashboard/proposals/Proposals.vue'
                    )
            },
            {
                path: 'proposals/create',
                name: 'dashboard-proposals-create',
                meta: {
                    roleAccess: ['user']
                },
                component: () =>
                    import(
                        /* webpackChunkName: "dashboard-proposals-create" */ '@/views/dashboard/proposals/create/ProposalCreate.vue'
                    )
            },
            {
                path: 'proposals/approval',
                name: 'dashboard-proposals-approval',
                meta: {
                    roleAccess: ['lecturer']
                },
                component: () =>
                    import(
                        /* webpackChunkName: "dashboard-proposals-approval" */ '@/views/dashboard/proposals/ProposalsApproval.vue'
                    )
            },
            {
                path: 'proposals/:id',
                name: 'dashboard-proposals-detail',
                meta: {
                    roleAccess: ['lecturer', 'supervisor', 'user']
                },
                component: () =>
                    import(
                        /* webpackChunkName: "dashboard-proposals-detail" */ '@/views/dashboard/proposals/show/ProposalDetail.vue'
                    )
            },
            {
                path: 'proposals/:id/update',
                name: 'dashboard-proposals-update',
                meta: {
                    roleAccess: ['user']
                },
                component: () =>
                    import(
                        /* webpackChunkName: "dashboard-proposals-update" */ '@/views/dashboard/proposals/update/ProposalUpdate.vue'
                    )
            },
            {
                path: 'proposals/:id/review',
                name: 'dashboard-proposals-review',
                meta: {
                    roleAccess: ['lecturer']
                },
                component: () =>
                    import(
                        /* webpackChunkName: "dashboard-proposals-review" */ '@/views/dashboard/proposals/review/ProposalReview.vue'
                    )
            },
            {
                path: 'questionnaires/approval',
                name: 'dashboard-questionnaires-approval',
                meta: {
                    roleAccess: ['lecturer']
                },
                component: () =>
                    import(
                        /* webpackChunkName: "dashboard-questionnaires-approval" */ '@/views/dashboard/questionnaires/QuestionnaireApproval.vue'
                    )
            },
            {
                path: 'questionnaires/problem-solving',
                name: 'dashboard-questionnaires-problemsolving',
                meta: {
                    roleAccess: ['user', 'lecturer']
                },
                component: () =>
                    import(
                        /* webpackChunkName: "dashboard-questionnaires-problemsolving" */ '@/views/dashboard/questionnaires/detail/ProblemSolving.vue'
                    )
            },
            {
                path: 'questionnaires/problem-solving/:id',
                name: 'dashboard-questionnaires-problemsolving-show',
                meta: {
                    roleAccess: ['user', 'lecturer']
                },
                component: () =>
                    import(
                        /* webpackChunkName: "dashboard-questionnaires-problemsolving-show" */ '@/views/dashboard/questionnaires/detail/ProblemSolving.vue'
                    )
            },
            {
                path: 'questionnaires/motivation',
                name: 'dashboard-questionnaires-motivation',
                meta: {
                    roleAccess: ['user', 'lecturer']
                },
                component: () =>
                    import(
                        /* webpackChunkName: "dashboard-questionnaires-motivation" */ '@/views/dashboard/questionnaires/detail/Motivation.vue'
                    )
            },
            {
                path: 'questionnaires/motivation/:id',
                name: 'dashboard-questionnaires-motivation-show',
                meta: {
                    roleAccess: ['user', 'lecturer']
                },
                component: () =>
                    import(
                        /* webpackChunkName: "dashboard-questionnaires-motivation-show" */ '@/views/dashboard/questionnaires/detail/Motivation.vue'
                    )
            },
            {
                path: 'questionnaires/perception',
                name: 'dashboard-questionnaires-perception',
                meta: {
                    roleAccess: ['user', 'lecturer']
                },
                component: () =>
                    import(
                        /* webpackChunkName: "dashboard-questionnaires-perception" */ '@/views/dashboard/questionnaires/detail/Perception.vue'
                    )
            },
            {
                path: 'questionnaires/perception/:id',
                name: 'dashboard-questionnaires-perception-show',
                meta: {
                    roleAccess: ['user', 'lecturer']
                },
                component: () =>
                    import(
                        /* webpackChunkName: "dashboard-questionnaires-perception-show" */ '@/views/dashboard/questionnaires/detail/Perception.vue'
                    )
            },
            {
                path: 'questionnaires/peer-review',
                name: 'dashboard-questionnaires-peerreview',
                meta: {
                    roleAccess: ['user', 'lecturer']
                },
                component: () =>
                    import(
                        /* webpackChunkName: "dashboard-questionnaires-peerreview" */ '@/views/dashboard/questionnaires/PeerReviewQuestionnaires.vue'
                    )
            },
            {
                path: 'questionnaires/peer-review/create',
                name: 'dashboard-questionnaires-peerreview-create',
                meta: {
                    roleAccess: ['user', 'lecturer']
                },
                component: () =>
                    import(
                        /* webpackChunkName: "dashboard-questionnaires-peerreview-create" */ '@/views/dashboard/questionnaires/detail/PeerReview.vue'
                    )
            },
            {
                path: 'questionnaires/peer-review/create-questionnaire',
                name: 'dashboard-questionnaires-peerreview-create-questionnaire',
                meta: {
                    roleAccess: ['user', 'lecturer']
                },
                component: () =>
                    import(
                        /* webpackChunkName: "dashboard-questionnaires-peerreview-create-questionnaire" */ '@/views/dashboard/questionnaires/detail/PeerReviewQuestion.vue'
                    )
            },
            {
                path: 'questionnaires/peer-review/:id',
                name: 'dashboard-questionnaires-peerreview-show',
                meta: {
                    roleAccess: ['user', 'lecturer', 'supervisor']
                },
                component: () =>
                    import(
                        /* webpackChunkName: "dashboard-questionnaires-peerreview-show" */ '@/views/dashboard/questionnaires/detail/PeerReviewRecords.vue'
                    )
            },
            {
                path: 'questionnaires/360-assessment',
                name: 'dashboard-questionnaires-360',
                meta: {
                    roleAccess: ['user', 'lecturer', 'supervisor']
                },
                component: () =>
                    import(
                        /* webpackChunkName: "dashboard-questionnaires-360" */ '@/views/dashboard/questionnaires/360Questionnaires.vue'
                    )
            },
            {
                path: 'questionnaires/360-assessment/:id',
                name: 'dashboard-questionnaires-360-show',
                meta: {
                    roleAccess: ['user', 'lecturer', 'supervisor']
                },
                component: () =>
                    import(
                        /* webpackChunkName: "dashboard-questionnaires-360-show" */ '@/views/dashboard/questionnaires/detail/360.vue'
                    )
            },
            {
                path: 'questionnaires/360-assessment/:id/update',
                name: 'dashboard-questionnaires-360-update',
                meta: {
                    roleAccess: ['user', 'lecturer', 'supervisor']
                },
                component: () =>
                    import(
                        /* webpackChunkName: "dashboard-questionnaires-360-update" */ '@/views/dashboard/questionnaires/detail/360.vue'
                    )
            },
            {
                path: 'questionnaires/360-assessment/create',
                name: 'dashboard-questionnaires-360-create',
                meta: {
                    roleAccess: ['user', 'lecturer', 'supervisor']
                },
                component: () =>
                    import(
                        /* webpackChunkName: "dashboard-questionnaires-360-create" */ '@/views/dashboard/questionnaires/detail/360.vue'
                    )
            },
            {
                path: 'questionnaires/export',
                name: 'dashboard-questionnaires-export',
                meta: {
                    roleAccess: ['lecturer']
                },
                component: () =>
                    import(
                        /* webpackChunkName: "dashboard-questionnaires-export" */ '@/views/dashboard/questionnaires/export/ExportQuestionnaire.vue'
                    )
            },
            {
                path: 'questionnaires/report',
                name: 'dashboard-questionnaires-report',
                meta: {
                    roleAccess: ['lecturer']
                },
                component: () =>
                    import(
                        /* webpackChunkName: "dashboard-questionnaires-report" */ '@/views/dashboard/questionnaires/report/ReportQuestionnaire.vue'
                    )
            }
        ]
    },
    {
        path: '/email',
        name: 'email',
        meta: {
            group: 'email',
            onlyUnvalidatedUser: true
        },
        component: BlankLayout,
        children: [
            {
                path: 'verify/expired',
                name: 'email-verify-expired',
                component: () =>
                    import(
                        /* webpackChunkName: "email-verify-expired" */ '@/views/email/VerifyExpired.vue'
                    )
            },
            {
                path: 'verify/success',
                name: 'email-verify-success',
                component: () =>
                    import(
                        /* webpackChunkName: "email-verify-success" */ '@/views/email/VerifySuccess.vue'
                    )
            }
        ]
    }
];

export default routes;
