<template>
    <div class="row w-100 min-vh-100 gap-0 m-0">
        <div class="d-none d-lg-block col-6 p-0">
            <div
                class="login-bg min-vh-100 h-100 w-100"
                :style="{
                    backgroundImage: `url(${require('@/assets/images/login.png')})`
                }"
            ></div>
        </div>
        <div class="col-12 col-lg-6 p-0">
            <div
                class="d-flex flex-column"
                :style="{
                    padding: '4.5rem',
                    maxWidth: '640px',
                    margin: '0 auto'
                }"
            >
                <div class="d-flex justify-content-center flex-column">
                    <div class="text-center mb-4">
                        <img
                            class="mb-5"
                            src="/media/logo.png"
                            alt="Logo"
                            width="150"
                        />

                        <h4
                            class="fw-bold fs-3"
                            v-text="$t('form.login.title')"
                        ></h4>
                        <p
                            class="text-secondary"
                            v-text="$t('form.login.subtitle')"
                        ></p>
                    </div>

                    <el-form
                        :model="credential"
                        ref="loginForm"
                        class="form mb-4"
                        :rules="loginFormRules"
                        @submit.prevent="onSubmit"
                    >
                        <el-form-item prop="email" class="mb-3" required>
                            <label
                                class="mb-1"
                                v-text="$t('input.email.label')"
                            ></label>
                            <el-input
                                v-model="credential.email"
                                type="text"
                                :placeholder="$t('input.email.placeholder')"
                                size="large"
                            ></el-input>
                        </el-form-item>

                        <el-form-item prop="password" class="mb-2" required>
                            <label
                                class="mb-1"
                                v-text="$t('input.password.label')"
                            ></label>
                            <el-input
                                v-model="credential.password"
                                :placeholder="$t('input.password.placeholder')"
                                size="large"
                                :type="passwordType"
                            >
                                <template #append>
                                    <span
                                        class="user-select-none"
                                        style="cursor: pointer"
                                        v-text="
                                            passwordType === 'text'
                                                ? 'Hide'
                                                : 'Show'
                                        "
                                        @click="showPassword"
                                    ></span>
                                </template>
                            </el-input>
                        </el-form-item>

                        <div
                            class="d-flex justify-content-between align-items-center mb-4"
                        >
                            <el-checkbox
                                v-model="remember"
                                :label="$t('form.rememberMe')"
                                size="large"
                            />

                            <router-link
                                class="text-decoration-none text-primary fw-bold mb-0 user-select-none"
                                to="/forgot-password"
                                style="font-size: 14px"
                            >
                                {{ $t('form.forgotPassword') }}
                            </router-link>
                        </div>

                        <div>
                            <el-button
                                class="w-100"
                                size="large"
                                type="primary"
                                native-type="submit"
                                v-loading.fullscreen.lock="isLoading"
                            >
                                {{ $t('login') }}
                            </el-button>

                            <!-- <el-divider />

                            <button
                                type="button"
                                class="d-block text-decoration-none w-100 btn shadow-sm border-gray-light"
                                @click="getGoogleUrl"
                            >
                                <img
                                    :src="GoogleLogo"
                                    :width="30"
                                    alt="Google"
                                />
                                <span
                                    class="ms-1 text-secondary"
                                    style="font-size: 14px"
                                >
                                    Sign In with Google</span
                                >
                            </button> -->
                        </div>
                    </el-form>
                </div>

                <p class="mb-0 mt-5 text-center" style="font-size: 14px">
                    {{ $t('form.dontHaveAnAccountYet') }}
                    <router-link
                        class="text-decoration-none text-primary fw-bold mb-0"
                        to="/register"
                        >{{ $t('signUp') }}</router-link
                    >
                </p>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref, inject } from 'vue';
import { useStore } from 'vuex';
import { Actions } from '@/store/enums/StoreEnums';
import GoogleLogo from '@/assets/icons/google.svg';

import { Authentication as loginFormRules } from '@/core/helpers/validation';
import ApiService from '@/core/services/ApiService';

export default defineComponent({
    name: 'LoginPage',
    setup() {
        const app: any = inject('app');
        const $toast: any = inject('$toast');
        const store = useStore();

        const credential = ref({
            email: '',
            password: ''
        });
        const remember = ref(false);
        const isLoading = ref(false);
        const passwordType = ref('password');
        const loginForm: any = ref('');

        const showPassword = () => {
            if (passwordType.value === 'password') passwordType.value = 'text';
            else passwordType.value = 'password';
        };

        const getGoogleUrl = async () => {
            try {
                const { data } = await ApiService.get('/login/google-redirect');
                window.location.href = data;
            } catch (e) {
                $toast.error(e);
            }
        };

        const onSubmit = () => {
            isLoading.value = true;
            loginForm.value.validate(async (valid: boolean) => {
                if (valid) {
                    try {
                        await store.dispatch(Actions.LOGIN, {
                            email: credential.value.email,
                            password: credential.value.password,
                            remember: remember.value
                        });

                        window.location.href = '/dashboard';
                    } catch (e) {
                        $toast.error(e);
                    } finally {
                        isLoading.value = false;
                    }
                } else {
                    isLoading.value = false;
                    $toast.warning('Mohon periksa kembali form anda');
                }
            });
        };

        return {
            loginFormRules,
            credential,
            remember,
            isLoading,
            passwordType,
            loginForm,
            GoogleLogo,
            showPassword,
            getGoogleUrl,
            onSubmit
        };
    }
});
</script>

<style lang="scss" scoped>
.login-bg {
    z-index: -1;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: rgba(0, 0, 0, 0.5);
}
.google-auth {
    background-color: #fff;
    border-radius: 5px;
    padding: 0.6rem 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease-in-out;

    img {
        height: 4rem;
        margin-right: 1rem;
        font-size: 1.8rem;
        box-shadow: 0 1px 13px 0 rgb(0 0 0 / 15%);
    }
}
</style>
