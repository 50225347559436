import { createI18n } from 'vue-i18n';

import enUS from '../locales/en-US.json';
import id from '../locales/id.json';

export const i18n = createI18n({
    locale: window.localStorage.getItem('locale') || 'en-US',
    messages: {
        'en-US': enUS,
        id: id
    }
});

export default i18n;
