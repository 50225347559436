import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { PaginationInterface } from '@/interfaces/Pagination';
import { initialPagination } from '@/core/helpers/default-pagination';
import ApiService from '@/core/services/ApiService';
import { CompanyInterface } from '@/interfaces/Company';
import { Company } from '@/views/dashboard/companies/_form/_type';

interface CompanyInfo {
    data: CompanyInterface[];
    meta: PaginationInterface;
}

@Module
export default class CampusModule extends VuexModule implements CompanyInfo {
    data: CompanyInterface[] = [];
    meta = initialPagination;

    /**
     * Get All Companies
     * @returns object
     */
    get getCompanies(): {
        data: CompanyInterface[];
        meta: PaginationInterface;
    } {
        return {
            data: this.data,
            meta: this.meta
        };
    }

    @Mutation
    [Mutations.SET_COMPANIES](payload: {
        data: CompanyInterface[];
        meta: PaginationInterface;
    }): void {
        this.data = payload.data;
        this.meta = payload.meta;
    }

    @Mutation
    [Mutations.SET_COMPANY](payload: CompanyInterface): void {
        this.data = this.data.map((company) =>
            payload.id === company.id ? { ...company, ...payload } : company
        );
    }

    @Mutation
    [Mutations.REMOVE_COMPANY](id: number): void {
        this.data = this.data.filter((company) => company.id !== id);
        this.meta.count -= 1;
    }

    @Action({ rawError: true })
    [Actions.GET_COMPANY_LIST](query?: any): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            ApiService.get(`/companies`, query)
                .then(({ data }) => {
                    this.context.commit(Mutations.SET_COMPANIES, data.data);
                    resolve(data.data);
                })
                .catch((e) => {
                    reject(e);
                });
        });
    }

    @Action({ rawError: true })
    [Actions.CREATE_COMPANY](payload: Company): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            ApiService.post(
                `/universities/${payload.university_id}/companies`,
                {
                    ...payload,
                    university_id: undefined
                }
            )
                .then(({ data }) => {
                    this.context.commit(Mutations.ADD_COMPANY, data.data);
                    resolve(data.data);
                })
                .catch((e) => {
                    reject(e);
                });
        });
    }

    @Action({ rawError: true })
    [Actions.UPDATE_COMPANY](payload: {
        data: CompanyInterface;
        company_id: number;
    }): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            ApiService.post(`/companies/${payload.company_id}`, payload.data)
                .then(({ data }) => {
                    this.context.commit(Mutations.SET_COMPANY, data.data);
                    resolve(data.data);
                })
                .catch((e) => {
                    reject(e);
                });
        });
    }

    @Action({ rawError: true })
    [Actions.DELETE_COMPANY](id: number): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            ApiService.delete(`/companies/${id}`)
                .then(({ data }) => {
                    this.context.commit(Mutations.REMOVE_COMPANY, id);
                    resolve(data.data);
                })
                .catch((e) => {
                    reject(e);
                });
        });
    }
}
