import HeaderMenu from '../config/header';
import SidebarMenu, { SidebarContent } from '../config/sidebar';
import { PathBasedMenu } from '@/store/modules/LayoutModule';
import { RoleInterface } from '@/interfaces/Role';
import objectPath from 'object-path';
import store from '@/store';

export interface LayoutConfig {
    header: PathBasedMenu[];
    sidebar: SidebarContent[];
}

class LayoutService {
    /**
     * @description initialize default layout
     */
    public static init(role: RoleInterface | 'guest'): void {
        const header = LayoutService.initHeader(role);
        const sidebar = LayoutService.initAside(role);

        const layout = {
            sidebar
        };

        window.localStorage.setItem('layout', JSON.stringify(layout));
    }

    /**
     * @description init header
     */
    public static initHeader(role: RoleInterface | 'guest'): void {
        let header;

        if (role === 'guest') {
            header = objectPath.get(HeaderMenu, `${role}HeaderMenu`);
        } else {
            header = objectPath.get(
                HeaderMenu,
                `${role.access_code}HeaderMenu`
            );
        }

        return header;
    }

    /**
     * @description init aside
     */
    public static initAside(role: RoleInterface | 'guest'): void {
        let aside;

        if (role === 'guest') {
            aside = objectPath.get(SidebarMenu, `${role}SidebarMenu`);
        } else {
            aside = objectPath.get(
                SidebarMenu,
                `${role.access_code}SidebarMenu`
            );
        }

        return aside;
    }
}

export default LayoutService;
