import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import ApiService from '@/core/services/ApiService';
import { RoleInterface } from '@/interfaces/Role';

interface RoleInfo {
    roles: RoleInterface[];
}

@Module
export default class RoleModule extends VuexModule implements RoleInfo {
    roles: RoleInterface[] = [];

    /**
     * Get All Roles
     * @returns object
     */
    get getRoles(): RoleInterface[] {
        return this.roles;
    }

    @Mutation
    [Mutations.SET_ROLES](payload: RoleInterface[]): void {
        this.roles = payload;
    }

    @Action({ rawError: true })
    [Actions.GET_ROLE_LIST](): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            ApiService.get(`/roles`)
                .then(({ data }) => {
                    this.context.commit(Mutations.SET_ROLES, data.data);
                    resolve(data.data);
                })
                .catch((e) => {
                    reject(e);
                });
        });
    }
}
