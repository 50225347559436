import { nextTick } from 'vue';
import { createRouter, createWebHistory } from 'vue-router';
import store from '@/store';
import routes from './routes';

import { UserAuthInfo } from '@/interfaces/User';
import { getToken } from '@/core/services/JwtService';

const DEFAULT_TITLE = process.env.VUE_APP_TITLE;

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

router.beforeEach((to, from, next) => {
    const { isAuthenticated, user }: UserAuthInfo = store.getters['getAuth'];

    if (to.matched.some((record) => record.meta.disableIfAuthenticated)) {
        if (isAuthenticated) {
            next('/dashboard');
        } else {
            next();
        }
    }

    if (to.matched.some((record) => record.meta.requiresAuth)) {
        if (isAuthenticated) {
            next();
        } else {
            next({
                path: '/login',
                query: {
                    redirect: to.fullPath
                }
            });
        }
    } else {
        next();
    }
});

router.afterEach((to, from) => {
    // Use next tick to handle router history correctly
    // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
    nextTick(() => {
        document.title = to.meta.title || DEFAULT_TITLE;
    });
});

export default router;
