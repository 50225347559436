import { PaginationInterface } from '@/interfaces/Pagination';

/**
    Initial default pagination
 */
export const initialPagination: PaginationInterface = {
    total: 0,
    count: 0,
    limit: 15,
    current_page: 1,
    total_pages: 0
};
