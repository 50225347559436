import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { PaginationInterface } from '@/interfaces/Pagination';
import { initialPagination } from '@/core/helpers/default-pagination';
import ApiService from '@/core/services/ApiService';
import { ProjectInterface } from '@/interfaces/Project';

interface ProjectInfo {
    projectList: {
        data: ProjectInterface[];
        meta: PaginationInterface;
    };
    projectApprovalList: {
        data: ProjectInterface[];
        meta: PaginationInterface;
    };
}

@Module
export default class ProjectModule extends VuexModule implements ProjectInfo {
    projectList: { data: ProjectInterface[]; meta: PaginationInterface } = {
        data: [],
        meta: initialPagination
    };
    projectApprovalList: {
        data: ProjectInterface[];
        meta: PaginationInterface;
    } = {
        data: [],
        meta: initialPagination
    };

    /**
     * Get All Projects
     * @returns object
     */
    get getProjects(): {
        data: ProjectInterface[];
        meta: PaginationInterface;
    } {
        return {
            data: this.projectList.data,
            meta: this.projectList.meta
        };
    }

    /**
     * Get All Approval Projects
     * @returns object
     */
    get getApprovalProjects(): {
        data: ProjectInterface[];
        meta: PaginationInterface;
    } {
        return {
            data: this.projectApprovalList.data,
            meta: this.projectApprovalList.meta
        };
    }

    @Mutation
    [Mutations.SET_PROJECTS](payload: {
        data: ProjectInterface[];
        meta: PaginationInterface;
    }): void {
        this.projectList.data = payload.data;
        this.projectList.meta = payload.meta;
    }

    @Mutation
    [Mutations.SET_PROJECTS_APPROVAL](payload: {
        data: ProjectInterface[];
        meta: PaginationInterface;
    }): void {
        this.projectApprovalList.data = payload.data;
        this.projectApprovalList.meta = payload.meta;
    }

    @Mutation
    [Mutations.SET_PROJECT](payload: any): void {
        this.projectList.data = this.projectList.data.map((team) =>
            payload.id === team.id ? { ...team, ...payload } : team
        );
    }

    @Mutation
    [Mutations.REMOVE_PROJECT](id: number): void {
        this.projectList.data = this.projectList.data.filter(
            (team) => team.id !== id
        );
        this.projectList.meta.count -= 1;
    }

    @Action({ rawError: true })
    [Actions.GET_PROJECT_LIST](query?: any): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            ApiService.get(`/projects`, query)
                .then(({ data }) => {
                    this.context.commit(Mutations.SET_PROJECTS, data.data);
                    resolve(data.data);
                })
                .catch((e) => {
                    reject(e);
                });
        });
    }

    @Action({ rawError: true })
    [Actions.GET_PROJECT_APPROVAL_LIST](query?: any): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            ApiService.get(`/projects/approval`, query)
                .then(({ data }) => {
                    this.context.commit(
                        Mutations.SET_PROJECTS_APPROVAL,
                        data.data
                    );
                    resolve(data.data);
                })
                .catch((e) => {
                    reject(e);
                });
        });
    }

    @Action({ rawError: true })
    [Actions.UPDATE_PROJECT](payload: {
        data: ProjectInterface[];
        team_id: number;
    }): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            ApiService.patch(`/projects/${payload.team_id}`, payload.data)
                .then(({ data }) => {
                    this.context.commit(Mutations.SET_PROJECT, data.data);
                    resolve(data.data);
                })
                .catch((e) => {
                    reject(e);
                });
        });
    }
}
