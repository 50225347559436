<template>
    <div>
        <div class="mb-4">
            <el-button
                v-show="!hideButton"
                type="info"
                size="large"
                :disabled="isDisabledBtn"
                @click="handleVerifyEmail"
            >
                {{ $t('verifyMyEmail') }}
            </el-button>
        </div>

        <p v-show="isDisabledBtn || hideButton" class="text-secondary">
            {{ $t('didntReceiveEmail') }}

            <br />

            <span
                class="text-decoration-none cursor-pointer user-select-none"
                :class="
                    isDisabledResend
                        ? 'cursor-disabled text-secondary-light'
                        : 'text-info'
                "
                v-on="!isDisabledResend ? { click: handleVerifyEmail } : {}"
            >
                {{ $t('resendVerificationEmail') }}
            </span>

            (
            <template v-if="countdown">
                <span class="text-info" v-text="countdown"></span>
            </template>
            <template v-else> 01:00 </template>
            )
        </p>
    </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from 'vue';
import { useCookies } from 'vue3-cookies';
import { useStore } from 'vuex';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

import { Actions } from '@/store/enums/StoreEnums';

dayjs.extend(duration);

export default defineComponent({
    props: {
        hideButton: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    setup(props: { hideButton?: boolean } = { hideButton: false }) {
        const store = useStore();
        const { cookies } = useCookies();
        const isDisabledBtn = ref(false);
        const isDisabledResend = ref(false);
        const countdown = ref('');
        const intervalId = ref();

        const count = () => {
            const now = dayjs();

            if (cookies.get('ev_t')) {
                const diffMinute = dayjs(cookies.get('ev_t')).diff(
                    now,
                    'seconds'
                );

                if (diffMinute >= 0) {
                    isDisabledResend.value = true;
                    const duration = dayjs.duration(
                        dayjs(cookies.get('ev_t')).diff(now)
                    );
                    const minutes = duration
                        .minutes()
                        .toString()
                        .padStart(2, '0');
                    const seconds = duration
                        .seconds()
                        .toString()
                        .padStart(2, '0');
                    countdown.value = `${minutes}:${seconds}`;
                } else {
                    countdown.value = '';
                    isDisabledResend.value = false;
                    clearInterval(intervalId.value);
                }
            }
        };

        const setCounter = () => {
            const t = dayjs().add(1, 'minute');
            cookies.set('ev_t', String(t));

            return t;
        };

        const handleVerifyEmail = async () => {
            isDisabledBtn.value = true;

            const newTimestamp = setCounter();
            if (newTimestamp) {
                intervalId.value = setInterval(count, 1000);
            }

            await store.dispatch(Actions.RESEND_VERIFICATION_EMAIL);
        };

        onMounted(() => {
            if (cookies.get('ev_t')) {
                isDisabledBtn.value = true;

                const now = dayjs();
                if (cookies.get('ev_t')) {
                    const diffMinute = dayjs(cookies.get('ev_t')).diff(
                        now,
                        'seconds'
                    );

                    if (diffMinute >= 0) {
                        isDisabledResend.value = true;
                        intervalId.value = setInterval(count, 1000);
                    } else {
                        countdown.value = '';
                        clearInterval(intervalId.value);
                    }
                }
            }
        });

        return {
            countdown,
            isDisabledBtn,
            isDisabledResend,
            handleVerifyEmail
        };
    }
});
</script>
