export interface SidebarContent {
    group: string;
    content: any;
}

const adminSidebarMenu: SidebarContent[] = [
    {
        group: 'Dashboard',
        content: [
            {
                group: 'Dashboard',
                icon: 'bi bi-grid-1x2',
                content: [
                    {
                        name: 'Overview',
                        path: '/dashboard',
                        hidden: false,
                        exact: true
                    }
                ]
            },
            {
                group: 'Faculties',
                icon: 'bi bi-window',
                content: [
                    {
                        name: 'Faculties',
                        path: '/dashboard/faculties',
                        hidden: false,
                        exact: true
                    },
                    {
                        name: 'Create New Faculty',
                        path: '/dashboard/faculties/create',
                        hidden: false,
                        exact: true
                    }
                ]
            },
            {
                group: 'Majors',
                icon: 'bi bi-award',
                content: [
                    {
                        name: 'Majors',
                        path: '/dashboard/majors',
                        hidden: false,
                        exact: true
                    },
                    {
                        name: 'Create New Major',
                        path: '/dashboard/majors/create',
                        hidden: false,
                        exact: true
                    }
                ]
            },
            {
                group: 'Companies',
                icon: 'bi bi-building',
                content: [
                    {
                        name: 'Companies',
                        path: '/dashboard/companies',
                        hidden: false,
                        exact: true
                    },
                    {
                        name: 'Create New Company',
                        path: '/dashboard/companies/create',
                        hidden: false,
                        exact: true,
                        icon: 'bi bi-building-add'
                    }
                ]
            },
            {
                group: 'User Management',
                icon: 'bi bi-people',
                content: [
                    {
                        name: 'Users',
                        path: '/dashboard/users',
                        hidden: false,
                        exact: true
                    },
                    {
                        name: 'Approval',
                        path: '/dashboard/users/approval',
                        hidden: false,
                        exact: true
                    },
                    {
                        name: 'Disapproved',
                        path: '/dashboard/users/disapproved',
                        hidden: false,
                        exact: true
                    },
                    {
                        name: 'Blocked',
                        path: '/dashboard/users/blocked',
                        hidden: false,
                        exact: true
                    }
                ]
            }
        ]
    }
];

const lecturerSidebarMenu: SidebarContent[] = [
    {
        group: 'Dashboard',
        content: [
            {
                group: 'Dashboard',
                icon: 'bi bi-grid-1x2',
                content: [
                    {
                        name: 'Overview',
                        path: '/dashboard',
                        hidden: false,
                        exact: true
                    }
                ]
            },
            {
                group: 'Class',
                icon: 'bi bi-card-list',
                content: [
                    {
                        name: 'My Class',
                        path: '/dashboard/my-class',
                        hidden: false,
                        exact: true
                    },
                    {
                        name: 'Create New Class',
                        path: '/dashboard/class/create',
                        hidden: false,
                        exact: true
                    }
                ]
            },
            {
                group: 'Proposals',
                icon: 'bi bi-journals',
                content: [
                    {
                        name: 'Proposals',
                        path: '/dashboard/proposals',
                        hidden: false,
                        exact: true
                    },
                    {
                        name: 'Approval',
                        path: '/dashboard/proposals/approval',
                        hidden: false,
                        exact: true
                    }
                ]
            },
            {
                group: 'Projects',
                icon: 'bi bi-folder',
                content: [
                    {
                        name: 'Projects',
                        path: '/dashboard/projects',
                        hidden: false,
                        exact: true
                    },
                    {
                        name: 'Approval',
                        path: '/dashboard/projects/approval',
                        hidden: false,
                        exact: true
                    }
                ]
            },
            {
                group: 'Group Teams',
                icon: 'bi bi-people',
                content: [
                    {
                        name: 'All Teams',
                        path: '/dashboard/teams',
                        hidden: false,
                        exact: true
                    },
                    {
                        name: 'Create New Team',
                        path: '/dashboard/teams/create',
                        hidden: false,
                        exact: true
                    }
                ]
            },
            {
                group: 'Questionnaires',
                icon: 'bi bi-envelope-paper',
                content: [
                    {
                        name: 'Approval',
                        path: '/dashboard/questionnaires/approval',
                        hidden: false,
                        exact: true
                    },
                    {
                        name: 'Peer Review',
                        path: '/dashboard/questionnaires/peer-review',
                        hidden: false,
                        exact: true
                    },
                    {
                        name: '360 Assessment',
                        path: '/dashboard/questionnaires/360-assessment',
                        hidden: false,
                        exact: true
                    },
                    {
                        name: 'Report',
                        path: '/dashboard/questionnaires/report',
                        hidden: false,
                        exact: true
                    },
                    {
                        name: 'Export',
                        path: '/dashboard/questionnaires/export',
                        hidden: false,
                        exact: true
                    }
                ]
            }
        ]
    }
];

const supervisorSidebarMenu: SidebarContent[] = [
    {
        group: 'Dashboard',
        content: [
            {
                group: 'Dashboard',
                icon: 'bi bi-grid-1x2',
                content: [
                    {
                        name: 'Overview',
                        path: '/dashboard',
                        hidden: false,
                        exact: true
                    }
                ]
            },
            {
                group: 'Projects',
                icon: 'bi bi-folder',
                content: [
                    {
                        name: 'Projects',
                        path: '/dashboard/projects',
                        hidden: false,
                        exact: true
                    }
                ]
            },
            {
                group: 'Questionnaires',
                icon: 'bi bi-envelope-paper',
                content: [
                    {
                        name: '360 Assessment',
                        path: '/dashboard/questionnaires/360-assessment',
                        hidden: false,
                        exact: true
                    }
                ]
            }
        ]
    }
];

const userSidebarMenu: SidebarContent[] = [
    {
        group: 'Dashboard',
        content: [
            {
                group: 'Class',
                icon: 'bi bi-card-list',
                content: [
                    {
                        name: 'My Class',
                        path: '/dashboard/my-class',
                        hidden: false,
                        exact: true
                    },
                    {
                        name: 'Enroll Class',
                        path: '/dashboard/class/enroll',
                        hidden: false,
                        exact: true
                    }
                ]
            },
            {
                group: 'Proposals',
                icon: 'bi bi-journals',
                content: [
                    {
                        name: 'My Proposals',
                        path: '/dashboard/proposals',
                        hidden: false,
                        exact: true
                    },
                    {
                        name: 'Create New Proposal',
                        path: '/dashboard/proposals/create',
                        hidden: false,
                        exact: true
                    }
                ]
            },
            {
                group: 'Projects',
                icon: 'bi bi-folder',
                content: [
                    {
                        name: 'My Projects',
                        path: '/dashboard/projects',
                        hidden: false,
                        exact: true
                    },
                    {
                        name: 'Create New Project',
                        path: '/dashboard/projects/create',
                        hidden: false,
                        exact: true
                    }
                ]
            },
            {
                group: 'Group Teams',
                icon: 'bi bi-people',
                content: [
                    {
                        name: 'My Teams',
                        path: '/dashboard/my-teams',
                        hidden: false,
                        exact: true
                    }
                ]
            },
            {
                group: 'Questionnaires',
                icon: 'bi bi-envelope-paper',
                content: [
                    {
                        name: 'Perception',
                        path: '/dashboard/questionnaires/perception',
                        hidden: false,
                        exact: true
                    },
                    {
                        name: 'Problem Solving',
                        path: '/dashboard/questionnaires/problem-solving',
                        hidden: false,
                        exact: true
                    },
                    {
                        name: 'Motivation',
                        path: '/dashboard/questionnaires/motivation',
                        hidden: false,
                        exact: true
                    },
                    {
                        name: '360 Assessment',
                        path: '/dashboard/questionnaires/360-assessment',
                        hidden: false,
                        exact: true
                    },
                    {
                        name: 'Peer Review',
                        path: '/dashboard/questionnaires/peer-review',
                        hidden: false,
                        exact: true
                    }
                ]
            }
        ]
    }
];

const guestSidebarMenu: SidebarContent[] = [
    {
        group: 'Dashboard',
        content: [
            {
                group: 'Dashboard',
                icon: 'bi bi-grid-1x2',
                content: [
                    {
                        name: 'Overview',
                        path: '/dashboard',
                        hidden: false,
                        exact: true
                    }
                ]
            }
        ]
    }
];

export default {
    adminSidebarMenu,
    lecturerSidebarMenu,
    supervisorSidebarMenu,
    userSidebarMenu,
    guestSidebarMenu
};
