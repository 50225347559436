import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, Transition as _Transition, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-30042d6e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "row w-100 min-vh-100 gap-0 m-0" }
const _hoisted_2 = { class: "d-none d-lg-block col-6 p-0" }
const _hoisted_3 = { class: "col-12 col-lg-6 p-0" }
const _hoisted_4 = {
  class: "d-flex flex-column w-100 h-100",
  style: {
                    padding: '4.5rem',
                    maxWidth: '640px',
                    margin: '0 auto'
                }
}
const _hoisted_5 = {
  key: 0,
  class: "d-flex justify-content-center align-items-center h-100"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_StepTwo = _resolveComponent("StepTwo")!
  const _component_StepOne = _resolveComponent("StepOne")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: "login-bg min-vh-100 h-100 w-100",
        style: _normalizeStyle({
                    backgroundImage: `url(${require('@/assets/images/register.png')})`
                })
      }, null, 4)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_Transition, {
          name: "fade",
          mode: "out-in"
        }, {
          default: _withCtx(() => [
            (_ctx.isSuccess)
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  _createVNode(_component_StepTwo, {
                    email: _ctx.data.email
                  }, null, 8, ["email"])
                ]))
              : (_openBlock(), _createBlock(_component_StepOne, {
                  key: 1,
                  "is-loading": _ctx.isLoading,
                  onOnSubmit: _ctx.handleOnSubmit
                }, null, 8, ["is-loading", "onOnSubmit"]))
          ]),
          _: 1
        })
      ])
    ])
  ]))
}