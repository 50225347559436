<template>
    <div>
        <div
            class="d-flex justify-content-center align-items-center flex-column"
        >
            <div class="text-center mb-4">
                <img
                    class="mb-5"
                    src="/media/logo.png"
                    alt="Logo"
                    width="150"
                />

                <h4
                    class="fw-bold fs-3"
                    v-text="$t('form.register.title')"
                ></h4>
                <p
                    class="text-secondary"
                    v-text="$t('form.register.subtitle')"
                ></p>
            </div>

            <el-form
                :model="registration"
                ref="registerForm"
                class="form mb-4"
                :rules="registerFormRules.personalInformation"
                @submit.prevent="handleSubmit"
            >
                <el-form-item prop="email" class="mb-3" required>
                    <label
                        class="mb-1"
                        v-text="$t('input.email.label')"
                    ></label>
                    <el-input
                        v-model="registration.email"
                        type="text"
                        :placeholder="$t('input.email.placeholder')"
                        size="large"
                    ></el-input>
                </el-form-item>

                <el-form-item class="mb-3">
                    <el-col :span="11">
                        <el-form-item prop="first_name" required>
                            <label
                                class="mb-1"
                                v-text="$t('input.firstName.label')"
                            ></label>
                            <el-input
                                v-model="registration.first_name"
                                type="text"
                                :placeholder="$t('input.firstName.placeholder')"
                                size="large"
                            ></el-input>
                        </el-form-item>
                    </el-col>

                    <el-col :span="1"></el-col>

                    <el-col :span="12">
                        <el-form-item prop="last_name" required>
                            <label
                                class="mb-1"
                                v-text="$t('input.lastName.label')"
                            ></label>
                            <el-input
                                v-model="registration.last_name"
                                type="text"
                                :placeholder="$t('input.lastName.placeholder')"
                                size="large"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                </el-form-item>

                <el-form-item prop="password" class="mb-3" required>
                    <label
                        class="mb-1"
                        v-text="$t('input.password.label')"
                    ></label>
                    <el-input
                        v-model="registration.password"
                        type="password"
                        :placeholder="$t('input.password.placeholder')"
                        size="large"
                    >
                    </el-input>
                </el-form-item>

                <el-form-item prop="confirm_password" class="mb-4" required>
                    <label
                        class="mb-1"
                        v-text="$t('input.confirmPassword.label')"
                    ></label>
                    <el-input
                        v-model="registration.confirm_password"
                        type="password"
                        :placeholder="$t('input.confirmPassword.placeholder')"
                        size="large"
                    >
                    </el-input>
                </el-form-item>

                <template v-if="!isConfirmPasswordValid">
                    <p
                        class="text-danger"
                        style="font-size: 12px; margin-top: -8px"
                    >
                        Password tidak sama
                    </p>
                </template>

                <div>
                    <el-button
                        class="w-100"
                        size="large"
                        type="primary"
                        native-type="submit"
                        v-loading.fullscreen.lock="isLoading"
                    >
                        {{ $t('continue') }}
                    </el-button>

                    <!-- <el-divider />

                    <button
                        type="button"
                        class="d-block text-decoration-none w-100 btn shadow-sm border-gray-light"
                        @click="getGoogleUrl"
                    >
                        <img :src="GoogleLogo" :width="30" alt="Google" />
                        <span
                            class="ms-1 text-secondary"
                            style="font-size: 14px"
                        >
                            Continue with Google</span
                        >
                    </button> -->
                </div>
            </el-form>
        </div>

        <p class="mb-0 mt-5 text-center" style="font-size: 14px">
            {{ $t('form.alreadyHaveAnAccount') }}
            <router-link
                class="text-decoration-none text-primary fw-bold mb-0"
                to="/login"
                >{{ $t('login') }}</router-link
            >
        </p>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref, inject, watch } from 'vue';

import { Registration as registerFormRules } from '@/core/helpers/validation';
import ApiService from '@/core/services/ApiService';
import GoogleLogo from '@/assets/icons/google.svg';

export default defineComponent({
    name: 'RegisterPageStepOne',
    props: {
        isLoading: {
            type: Boolean,
            required: true,
            default: false
        },
        onSubmit: {
            type: Function,
            required: true,
            default() {
                return {};
            }
        }
    },
    setup(props, { emit }) {
        const $toast: any = inject('$toast');
        const registerForm: any = ref('');
        const registration = ref({
            first_name: '',
            last_name: '',
            email: '',
            password: '',
            confirm_password: ''
        });
        const isConfirmPasswordValid = ref(true);

        const handleSubmit = () => {
            registerForm.value.validate(async (valid: boolean) => {
                if (valid && isConfirmPasswordValid.value) {
                    emit('onSubmit', registration.value);
                } else {
                    $toast.warning('Mohon periksa kembali form anda');
                }
            });
        };

        const getGoogleUrl = async () => {
            try {
                const { data } = await ApiService.get('/login/google-redirect');
                window.location.href = data;
            } catch (e) {
                $toast.error(e);
            }
        };

        watch(
            registration,
            ({ password, confirm_password }) => {
                if (password === confirm_password)
                    isConfirmPasswordValid.value = true;
                else isConfirmPasswordValid.value = false;
            },
            { deep: true }
        );

        return {
            registerForm,
            registerFormRules,
            registration,
            isConfirmPasswordValid,
            GoogleLogo,
            getGoogleUrl,
            handleSubmit
        };
    }
});
</script>
