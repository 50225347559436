import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { UserInterface } from '@/interfaces/User';
import { PaginationInterface } from '@/interfaces/Pagination';
import { initialPagination } from '@/core/helpers/default-pagination';
import ApiService from '@/core/services/ApiService';

interface ApprovalInfo {
    approval: {
        users: UserInterface[];
        meta: PaginationInterface;
    };
    disapproval: {
        users: UserInterface[];
        meta: PaginationInterface;
    };
}

@Module
export default class ApprovalModule extends VuexModule implements ApprovalInfo {
    approval: {
        users: UserInterface[];
        meta: PaginationInterface;
    } = {
        users: [],
        meta: initialPagination
    };
    disapproval: {
        users: UserInterface[];
        meta: PaginationInterface;
    } = {
        users: [],
        meta: initialPagination
    };

    /**
     * Get All Approval Requests
     * @returns object
     */
    get getApprovals(): {
        users: UserInterface[];
        meta: PaginationInterface;
    } {
        return this.approval;
    }

    /**
     * Get All Disapproved data
     * @returns object
     */
    get getDisapprovals(): {
        users: UserInterface[];
        meta: PaginationInterface;
    } {
        return this.disapproval;
    }

    @Mutation
    [Mutations.SET_APPROVALS](payload: {
        data: UserInterface[];
        meta: PaginationInterface;
    }): void {
        this.approval.users = payload.data;
        this.approval.meta = payload.meta;
    }

    @Mutation
    [Mutations.SET_DISAPPROVALS](payload: {
        data: UserInterface[];
        meta: PaginationInterface;
    }): void {
        this.disapproval.users = payload.data;
        this.disapproval.meta = payload.meta;
    }

    @Mutation
    [Mutations.REMOVE_APPROVAL](id: number): void {
        this.approval.users = this.approval.users.filter(
            (user) => user.id !== id
        );
        this.approval.meta.count -= 1;
    }

    @Mutation
    [Mutations.REMOVE_DISAPPROVAL](id: number): void {
        this.disapproval.users = this.disapproval.users.filter(
            (user) => user.id !== id
        );
        this.disapproval.meta.count -= 1;
    }

    @Action({ rawError: true })
    [Actions.GET_APPROVAL_LIST](query?: any): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            ApiService.get(`/users/unapproved`, query)
                .then(({ data }) => {
                    this.context.commit(Mutations.SET_APPROVALS, data.data);
                    resolve(data.data);
                })
                .catch((e) => {
                    reject(e);
                });
        });
    }

    @Action({ rawError: true })
    [Actions.GET_DISAPPROVAL_LIST](query?: any): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            ApiService.get(`/users/disapproved`, query)
                .then(({ data }) => {
                    this.context.commit(Mutations.SET_DISAPPROVALS, data.data);
                    resolve(data.data);
                })
                .catch((e) => {
                    reject(e);
                });
        });
    }

    @Action({ rawError: true })
    [Actions.APPROVE_USER](id: number): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            ApiService.post(`/users/${id}/approve`)
                .then(({ data }) => {
                    this.context.commit(Mutations.REMOVE_APPROVAL, id);
                    resolve(data.data);
                })
                .catch((e) => {
                    reject(e);
                });
        });
    }

    @Action({ rawError: true })
    [Actions.DISAPPROVE_USER](id: number): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            ApiService.post(`/users/${id}/disapprove`)
                .then(({ data }) => {
                    this.context.commit(Mutations.REMOVE_APPROVAL, id);
                    resolve(data.data);
                })
                .catch((e) => {
                    reject(e);
                });
        });
    }

    @Action({ rawError: true })
    [Actions.DELETE_DISAPPROVED_USER](id: number): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            ApiService.delete(`/users/${id}`)
                .then(({ data }) => {
                    this.context.commit(Mutations.REMOVE_DISAPPROVAL, id);
                    resolve(data.data);
                })
                .catch((e) => {
                    reject(e);
                });
        });
    }
}
