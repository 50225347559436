enum Actions {
    APPROVE_USER = 'approveUser',
    DISAPPROVE_USER = 'disapproveUser',
    LOGIN = 'login',
    LOGIN_GOOGLE = 'loginGoogle',
    LOGOUT = 'logout',
    SIGN_UP = 'signUp',
    GET_USERS_LIST = 'getUsersList',
    GET_BLOCKED_USERS_LIST = 'getBlockedUsersList',
    GET_APPROVAL_LIST = 'getApprovalList',
    GET_DISAPPROVAL_LIST = 'getDispprovalList',
    GET_ALL_FACULTY_LIST = 'getAllFacultyList',
    GET_FACULTY_LIST = 'getFacultyList',
    GET_MAJOR_LIST = 'getMajorList',
    GET_COMPANY_LIST = 'getCompanyList',
    GET_ALL_UNIVERSITY_LIST = 'getAllUniversityList',
    GET_ALL_MAJOR_LIST = 'getAllMajorList',
    GET_ROLE_LIST = 'getRoleList',
    GET_TEAM_LIST = 'getTeamList',
    GET_MY_TEAM_LIST = 'getMyTeamList',
    GET_MY_CLASS_LIST = 'getMyClassList',
    GET_CLASS_LIST = 'getClassList',
    GET_PROJECT_LIST = 'getProjectList',
    GET_PROJECT_APPROVAL_LIST = 'getProjectApprovalList',
    GET_PROPOSAL_LIST = 'getProposalList',
    GET_PROPOSAL_APPROVAL_LIST = 'getProposalApprovalList',
    GET_PROBLEM_SOLVING_QUESTIONNAIRE_LIST = 'getProblemSolvingQuestionnaireList',
    GET_MOTIVATION_QUESTIONNAIRE_LIST = 'getMotivationQuestionnaireList',
    GET_PERCEPTION_QUESTIONNAIRE_LIST = 'getPerceptionQuestionnaireList',
    GET_PEER_REVIEW_QUESTIONNAIRE_LIST = 'getPeerReviewQuestionnaireList',
    GET_THREE_SIXTY_QUESTIONNAIRE_LIST = 'getThreeSixtyQuestionnaireList',
    SET_PASSWORD = 'setPassword',
    CREATE_FACULTY = 'createFaculty',
    CREATE_MAJOR = 'createMajor',
    CREATE_COMPANY = 'createCompany',
    CREATE_TEAM = 'createTeam',
    CREATE_MY_TEAM = 'createMyTeam',
    CREATE_MY_CLASS = 'createMyClass',
    UPDATE_LOCALE = 'updateLocale',
    UPDATE_BREADCRUMB = 'updateBreadcrumb',
    UPDATE_LAYOUT = 'updateLayout',
    UPDATE_USERS = 'updateUsers',
    UPDATE_BLOCKED_USERS = 'updateBlockedUsers',
    UPDATE_USER = 'updateUser',
    UPDATE_USER_INFO = 'updateUserInfo',
    UPDATE_BLOCKED_USER = 'updateBlockedUser',
    UPDATE_FACULTY = 'updateFaculty',
    UPDATE_MAJOR = 'updateMajor',
    UPDATE_COMPANY = 'updateCompany',
    UPDATE_TEAM = 'updateTeam',
    UPDATE_MY_TEAM = 'updateMyTeam',
    UPDATE_MY_CLASS = 'updateMyClass',
    UPDATE_PROJECT = 'updateProject',
    UPDATE_PROPOSAL = 'updateProposal',
    RESEND_VERIFICATION_EMAIL = 'resendVerificationEmail',
    DEACTIVATE_USER = 'deactivateUser',
    DELETE_USER = 'deleteUser',
    DELETE_BLOCKED_USER = 'deleteBlockedUser',
    DELETE_DISAPPROVED_USER = 'deleteDisapprovedUser',
    DELETE_FACULTY = 'deleteFaculty',
    DELETE_MAJOR = 'deleteMajor',
    DELETE_COMPANY = 'deleteCompany',
    DELETE_TEAM = 'deleteTeam',
    DELETE_MY_TEAM = 'deleteMyTeam',
    DELETE_MY_CLASS = 'deleteMyClass',
    DELETE_PROJECT = 'deleteProject',
    DELETE_PROPOSAL = 'deleteProposal',
    UNBLOCK_USER = 'unblockUser'
}

enum Mutations {
    ADD_FACULTY = 'addFaculty',
    ADD_MAJOR = 'addMajor',
    ADD_COMPANY = 'addCompany',
    ADD_TEAM = 'addTeam',
    ADD_MY_TEAM = 'addMyTeam',
    ADD_MY_CLASS = 'addMyClass',
    SET_INFO = 'setInfo',
    SET_TOKEN = 'setToken',
    SET_LOCALE = 'setLocale',
    SET_BREADCRUMB = 'setBreadcrumb',
    SET_LAYOUT = 'setLayout',
    SET_REGISTER_STATE = 'setRegisterState',
    SET_USERS = 'setUsers',
    SET_BLOCKED_USERS = 'setBlockedUsers',
    SET_USER = 'setUser',
    SET_BLOCKED_USER = 'setBlockedUser',
    SET_APPROVALS = 'setApprovals',
    SET_DISAPPROVALS = 'setDisapprovals',
    SET_ALL_FACULTIES = 'setAllFaculties',
    SET_ALL_MAJORS = 'setAllMajors',
    SET_FACULTIES = 'setFaculties',
    SET_FACULTY = 'setFaculty',
    SET_UNIVERSITIES = 'setUniversities',
    SET_COMPANIES = 'setCompanies',
    SET_MAJORS = 'setMajors',
    SET_MAJOR = 'setMajor',
    SET_COMPANY = 'setCompany',
    SET_ROLES = 'setRoles',
    SET_TEAMS = 'setTeams',
    SET_TEAM = 'setTeam',
    SET_MY_TEAMS = 'setMyTeams',
    SET_MY_TEAM = 'setMyTeam',
    SET_MY_CLASSES = 'setMyClasses',
    SET_MY_CLASS = 'setMyClass',
    SET_CLASSES = 'setClasses',
    SET_PROJECTS = 'setProjects',
    SET_PROJECTS_APPROVAL = 'setProjectsApproval',
    SET_PROJECT = 'setProject',
    SET_PROPOSALS = 'setProposals',
    SET_PROPOSALS_APPROVAL = 'setProposalsApproval',
    SET_PROPOSAL = 'setProposal',
    SET_PROBLEM_SOLVING_QUESTIONNAIRE_LIST = 'setProblemSolvingQuestionnaireList',
    SET_MOTIVATION_QUESTIONNAIRE_LIST = 'setMotivationQuestionnaireList',
    SET_PERCEPTION_QUESTIONNAIRE_LIST = 'setPerceptionQuestionnaireList',
    SET_PEER_REVIEW_QUESTIONNAIRE_LIST = 'setPeerReviewQuestionnaireList',
    SET_THREE_SIXTY_QUESTIONNAIRE_LIST = 'setThreeSixtyQuestionnaireList',
    REMOVE_TOKEN = 'removeToken',
    REMOVE_INFO = 'removeInfo',
    REMOVE_LAYOUT = 'removeLayout',
    REMOVE_USER = 'removeUser',
    REMOVE_BLOCKED_USER = 'removeBlockedUser',
    REMOVE_APPROVAL = 'removeApproval',
    REMOVE_FACULTY = 'removeFaculty',
    REMOVE_MAJOR = 'removeMajor',
    REMOVE_DISAPPROVAL = 'removeDisapproval',
    REMOVE_COMPANY = 'removeCompany',
    REMOVE_TEAM = 'removeTeam',
    REMOVE_MY_TEAM = 'removeMyTeam',
    REMOVE_MY_CLASS = 'removeMyClass',
    REMOVE_PROJECT = 'removeProject',
    REMOVE_PROPOSAL = 'removeProposal',
    RESET_AUTH_STATE = 'resetAuthState'
}

export { Actions, Mutations };
