import { Action, Mutation, Module, VuexModule } from 'vuex-module-decorators';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { PaginationInterface } from '@/interfaces/Pagination';
import {
    MotivationInterface,
    PeerReviewInterface,
    PerceptionInterface,
    ProblemSolvingInterface,
    ThreeSixtyInterface
} from '@/interfaces/Questionnaire';
import { initialPagination } from '@/core/helpers/default-pagination';
import ApiService from '@/core/services/ApiService';

interface StoreInfo {
    problemSolvingList: {
        data: ProblemSolvingInterface[];
        meta: PaginationInterface;
    };
    motivationList: {
        data: MotivationInterface[];
        meta: PaginationInterface;
    };
    perceptionList: {
        data: PerceptionInterface[];
        meta: PaginationInterface;
    };
    peerReviewList: {
        data: PeerReviewInterface[];
        meta: PaginationInterface;
    };
    threeSixtyList: {
        data: ThreeSixtyInterface[];
        meta: PaginationInterface;
    };
}

@Module
export default class QuestionnaireModule
    extends VuexModule
    implements StoreInfo
{
    problemSolvingList: {
        data: ProblemSolvingInterface[];
        meta: PaginationInterface;
    } = {
        data: [],
        meta: initialPagination
    };
    motivationList: {
        data: MotivationInterface[];
        meta: PaginationInterface;
    } = {
        data: [],
        meta: initialPagination
    };
    perceptionList: {
        data: PerceptionInterface[];
        meta: PaginationInterface;
    } = {
        data: [],
        meta: initialPagination
    };
    peerReviewList: {
        data: PeerReviewInterface[];
        meta: PaginationInterface;
    } = {
        data: [],
        meta: initialPagination
    };
    threeSixtyList: {
        data: ThreeSixtyInterface[];
        meta: PaginationInterface;
    } = {
        data: [],
        meta: initialPagination
    };

    /**
     * Get problem solving questionnaire list for current page
     * @returns object
     */
    get getProblemSolvingQuestionnaire() {
        return this.problemSolvingList;
    }

    /**
     * Get motivation questionnaire list for current page
     * @returns object
     */
    get getMotivationQuestionnaire() {
        return this.motivationList;
    }

    /**
     * Get perception questionnaire list for current page
     * @returns object
     */
    get getPerceptionQuestionnaire() {
        return this.perceptionList;
    }

    /**
     * Get peer review questionnaire list for current page
     * @returns object
     */
    get getPeerReviewQuestionnaire() {
        return this.peerReviewList;
    }

    /**
     * Get 360 questionnaire list for current page
     * @returns object
     */
    get get360Questionnaire() {
        return this.threeSixtyList;
    }

    @Mutation
    [Mutations.SET_PROBLEM_SOLVING_QUESTIONNAIRE_LIST](payload: {
        data: ProblemSolvingInterface[];
        meta: PaginationInterface;
    }): void {
        this.problemSolvingList.data = payload.data;
        this.problemSolvingList.meta = payload.meta;
    }

    @Mutation
    [Mutations.SET_MOTIVATION_QUESTIONNAIRE_LIST](payload: {
        data: MotivationInterface[];
        meta: PaginationInterface;
    }): void {
        this.motivationList.data = payload.data;
        this.motivationList.meta = payload.meta;
    }

    @Mutation
    [Mutations.SET_PERCEPTION_QUESTIONNAIRE_LIST](payload: {
        data: PerceptionInterface[];
        meta: PaginationInterface;
    }): void {
        this.perceptionList.data = payload.data;
        this.perceptionList.meta = payload.meta;
    }

    @Mutation
    [Mutations.SET_PEER_REVIEW_QUESTIONNAIRE_LIST](payload: {
        data: PeerReviewInterface[];
        meta: PaginationInterface;
    }): void {
        this.peerReviewList.data = payload.data;
        this.peerReviewList.meta = payload.meta;
    }

    @Mutation
    [Mutations.SET_THREE_SIXTY_QUESTIONNAIRE_LIST](payload: {
        data: ThreeSixtyInterface[];
        meta: PaginationInterface;
    }): void {
        this.threeSixtyList.data = payload.data;
        this.threeSixtyList.meta = payload.meta;
    }

    @Action({ rawError: true })
    [Actions.GET_PROBLEM_SOLVING_QUESTIONNAIRE_LIST](
        query?: any
    ): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            ApiService.get(`/questionnaires/problem-solving`, query)
                .then(({ data }) => {
                    this.context.commit(
                        Mutations.SET_PROBLEM_SOLVING_QUESTIONNAIRE_LIST,
                        data.data
                    );
                    resolve(data.data);
                })
                .catch((e) => {
                    reject(e);
                });
        });
    }

    @Action({ rawError: true })
    [Actions.GET_MOTIVATION_QUESTIONNAIRE_LIST](query?: any): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            ApiService.get(`/questionnaires/motivation`, query)
                .then(({ data }) => {
                    this.context.commit(
                        Mutations.SET_MOTIVATION_QUESTIONNAIRE_LIST,
                        data.data
                    );
                    resolve(data.data);
                })
                .catch((e) => {
                    reject(e);
                });
        });
    }

    @Action({ rawError: true })
    [Actions.GET_PERCEPTION_QUESTIONNAIRE_LIST](query?: any): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            ApiService.get(`/questionnaires/perception`, query)
                .then(({ data }) => {
                    this.context.commit(
                        Mutations.SET_PERCEPTION_QUESTIONNAIRE_LIST,
                        data.data
                    );
                    resolve(data.data);
                })
                .catch((e) => {
                    reject(e);
                });
        });
    }

    @Action({ rawError: true })
    [Actions.GET_PEER_REVIEW_QUESTIONNAIRE_LIST](query?: any): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            ApiService.get(`/questionnaires/peer-review`, query)
                .then(({ data }) => {
                    this.context.commit(
                        Mutations.SET_PEER_REVIEW_QUESTIONNAIRE_LIST,
                        data.data
                    );
                    resolve(data.data);
                })
                .catch((e) => {
                    reject(e);
                });
        });
    }

    @Action({ rawError: true })
    [Actions.GET_THREE_SIXTY_QUESTIONNAIRE_LIST](query?: any): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            ApiService.get(`/questionnaires/360`, query)
                .then(({ data }) => {
                    this.context.commit(
                        Mutations.SET_THREE_SIXTY_QUESTIONNAIRE_LIST,
                        data.data
                    );
                    resolve(data.data);
                })
                .catch((e) => {
                    reject(e);
                });
        });
    }
}
