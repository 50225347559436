import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { UserInterface } from '@/interfaces/User';
import { PaginationInterface } from '@/interfaces/Pagination';
import { initialPagination } from '@/core/helpers/default-pagination';
import ApiService from '@/core/services/ApiService';
import { getStatus } from '@/core/helpers/fn';

interface UserInfo {
    activeUsers: {
        users: UserInterface[];
        meta: PaginationInterface;
    };
    blockedUsers: {
        users: UserInterface[];
        meta: PaginationInterface;
    };
}

@Module
export default class UserModule extends VuexModule implements UserInfo {
    activeUsers: { users: UserInterface[]; meta: PaginationInterface } = {
        users: [],
        meta: initialPagination
    };
    blockedUsers: { users: UserInterface[]; meta: PaginationInterface } = {
        users: [],
        meta: initialPagination
    };

    /**
     * Get All Active Users
     * @returns object
     */
    get getUsers(): { users: UserInterface[]; meta: PaginationInterface } {
        return this.activeUsers;
    }

    /**
     * Get All Active Users
     * @returns object
     */
    get getBlockedUsers(): {
        users: UserInterface[];
        meta: PaginationInterface;
    } {
        return this.blockedUsers;
    }

    @Mutation
    [Mutations.SET_USERS](payload: {
        data: UserInterface[];
        meta: PaginationInterface;
    }): void {
        this.activeUsers.users = payload.data;
        this.activeUsers.meta = payload.meta;
    }

    @Mutation
    [Mutations.SET_BLOCKED_USERS](payload: {
        data: UserInterface[];
        meta: PaginationInterface;
    }): void {
        this.blockedUsers.users = payload.data;
        this.blockedUsers.meta = payload.meta;
    }

    @Mutation
    [Mutations.SET_USER](payload: { id: number; data: UserInterface }): void {
        this.activeUsers.users = this.activeUsers.users.map((user) =>
            payload.id === user.id ? { ...user, ...payload.data } : user
        );
    }

    @Mutation
    [Mutations.SET_BLOCKED_USER](payload: {
        id: number;
        data: UserInterface;
    }): void {
        this.blockedUsers.users = this.blockedUsers.users.map((user) =>
            payload.id === user.id ? { ...user, ...payload.data } : user
        );
    }

    @Mutation
    [Mutations.REMOVE_USER](id: number): void {
        this.activeUsers.users = this.activeUsers.users.filter(
            (user) => user.id !== id
        );
        this.activeUsers.meta.count -= 1;
    }

    @Mutation
    [Mutations.REMOVE_BLOCKED_USER](id: number): void {
        this.blockedUsers.users = this.blockedUsers.users.filter(
            (user) => user.id !== id
        );
        this.blockedUsers.meta.count -= 1;
    }

    @Action({ rawError: true })
    [Actions.GET_USERS_LIST](query?: any): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            ApiService.get(`/users`, query)
                .then(({ data }) => {
                    this.context.commit(Mutations.SET_USERS, data.data);
                    resolve(data.data);
                })
                .catch((e) => {
                    reject(e);
                });
        });
    }

    @Action({ rawError: true })
    [Actions.GET_BLOCKED_USERS_LIST](query?: any): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            ApiService.get(`/users/blocked`, query)
                .then(({ data }) => {
                    this.context.commit(Mutations.SET_BLOCKED_USERS, data.data);
                    resolve(data.data);
                })
                .catch((e) => {
                    reject(e);
                });
        });
    }

    @Action({ rawError: true })
    [Actions.DEACTIVATE_USER](id: number): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            ApiService.post(`/users/${id}/block`)
                .then(({ data }) => {
                    this.context.commit(Mutations.SET_USER, {
                        id,
                        data: data.data
                    });
                    resolve(data.data);
                })
                .catch((e) => {
                    reject(e);
                });
        });
    }

    @Action({ rawError: true })
    [Actions.UNBLOCK_USER](id: number): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            ApiService.post(`/users/${id}/unblock`)
                .then(({ data }) => {
                    this.context.commit(Mutations.REMOVE_BLOCKED_USER, id);
                    resolve(data.data);
                })
                .catch((e) => {
                    reject(e);
                });
        });
    }

    @Action({ rawError: true })
    [Actions.DELETE_USER](id: number): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            ApiService.delete(`/users/${id}`)
                .then(({ data }) => {
                    this.context.commit(Mutations.REMOVE_USER, id);
                    resolve(data.data);
                })
                .catch((e) => {
                    reject(e);
                });
        });
    }

    @Action({ rawError: true })
    [Actions.DELETE_BLOCKED_USER](id: number): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            ApiService.delete(`/users/${id}`)
                .then(({ data }) => {
                    this.context.commit(Mutations.REMOVE_BLOCKED_USER, id);
                    resolve(data.data);
                })
                .catch((e) => {
                    reject(e);
                });
        });
    }

    @Action({ rawError: true })
    [Actions.SET_PASSWORD](payload: { password: string }): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            ApiService.post(`/me/create-password`, payload)
                .then(({ data }) => {
                    Promise.all([
                        this.context.commit(
                            Mutations.SET_INFO,
                            JSON.stringify(data.data)
                        )
                    ]).finally(() => {
                        resolve(data);
                    });
                })
                .catch((e) => {
                    reject(e);
                });
        });
    }

    @Action({ rawError: true })
    [Actions.UPDATE_USER_INFO](payload: any): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            ApiService.post(`/me/personal-info`, payload)
                .then(({ data }) => {
                    Promise.all([
                        this.context.commit(
                            Mutations.SET_INFO,
                            JSON.stringify(data.data)
                        ),
                        this.context.commit(
                            Mutations.SET_LAYOUT,
                            getStatus(data.data.status) === 'approved' &&
                                data.data.roles &&
                                data.data.roles.length
                                ? data.data.roles[0]
                                : 'guest'
                        )
                    ]).finally(() => {
                        resolve(data);
                    });
                })
                .catch((e) => {
                    reject(e);
                });
        });
    }
}
