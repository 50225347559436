<template><div></div></template>

<script lang="ts">
import { defineComponent, inject, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';

import { ElLoading } from 'element-plus';

import { Actions } from '@/store/enums/StoreEnums';

export default defineComponent({
    name: 'LoginGoogle',
    setup() {
        const $toast: any = inject('$toast');
        const store = useStore();
        const route = useRoute();

        const loginGoogle = async () => {
            const loading = ElLoading.service({
                lock: true,
                text: 'Logging In...'
            });

            try {
                await store.dispatch(Actions.LOGIN_GOOGLE, route.query.code);

                window.location.href = '/dashboard';
            } catch (e) {
                $toast.error(e);
                window.location.href = '/login';
            } finally {
                loading.close();
            }
        };

        onMounted(async () => {
            await loginGoogle();
        });

        return {};
    }
});
</script>
