<template>
    <div
        class="d-flex justify-content-center align-items-center bg-light min-vh-100"
    >
        <router-view></router-view>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({});
</script>
