/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-useless-escape */

export const Authentication = {
    email: [
        {
            required: true,
            message: 'Mohon isi email anda',
            trigger: 'blur'
        },
        {
            type: 'string',
            message: 'Mohon isi email yang valid',
            validator: (rule: any, value: string) =>
                /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value) || value === 'admin'
        }
    ],
    password: [
        {
            required: true,
            message: 'Mohon isi password anda',
            trigger: 'blur'
        }
    ]
};

export const ContactUs = {
    name: [
        {
            required: true,
            message: 'Mohon isi nama anda',
            trigger: 'blur'
        }
    ],
    email: [
        {
            required: true,
            message: 'Mohon isi email anda',
            trigger: 'blur'
        },
        {
            type: 'email',
            message: 'Mohon isi email yang valid',
            trigger: 'blur'
        }
    ],
    subject: [
        {
            required: true,
            message: 'Mohon isi subject anda',
            trigger: 'blur'
        }
    ],
    message: [
        {
            required: true,
            message: 'Mohon isi pesan anda',
            trigger: 'blur'
        }
    ]
};

export const Registration = {
    personalInformation: {
        first_name: [
            {
                required: true,
                message: 'Mohon isi nama depan anda',
                trigger: 'blur'
            },
            {
                min: 3,
                message: 'Panjang nama depan minimal 3 karakter',
                trigger: 'blur'
            }
        ],
        last_name: [
            {
                required: true,
                message: 'Mohon isi nama belakang anda',
                trigger: 'blur'
            },
            {
                min: 3,
                message: 'Panjang nama belakang minimal 3 karakter',
                trigger: 'blur'
            }
        ],
        email: [
            {
                required: true,
                message: 'Mohon isi email anda',
                trigger: 'blur'
            },
            {
                type: 'email',
                message: 'Mohon isi email yang valid',
                trigger: 'blur'
            }
        ],
        password: [
            {
                required: true,
                message: 'Mohon isi password anda',
                trigger: 'blur'
            },
            {
                min: 8,
                message: 'Panjang huruf minimal 8 karakter',
                trigger: 'blur'
            }
        ],
        confirm_password: [
            {
                required: true,
                message: 'Mohon isi kembali password anda',
                trigger: 'blur'
            },
            {
                min: 8,
                message: 'Panjang huruf minimal 8 karakter',
                trigger: 'blur'
            }
        ]
    }
};

export const Company = {
    university_id: [
        {
            required: true,
            message: 'Mohon isi universitas',
            trigger: 'blur'
        }
    ],
    company_name: [
        {
            required: true,
            message: 'Mohon isi nama perusahaan',
            trigger: 'blur'
        }
    ],
    email: [
        {
            required: true,
            message: 'Mohon isi email perusahaan',
            trigger: 'blur'
        },
        {
            type: 'email',
            message: 'Mohon isi email yang valid',
            trigger: 'blur'
        }
    ],
    phone: [
        {
            required: true,
            message: 'Mohon isi nomor telepon perusahaan',
            trigger: 'blur'
        }
    ],
    address: [
        {
            required: true,
            message: 'Mohon isi alamat perusahaan',
            trigger: 'blur'
        }
    ]
};

export const Faculty = {
    university_id: [
        {
            required: true,
            message: 'Mohon isi universitas',
            trigger: 'blur'
        }
    ],
    faculty_name: [
        {
            required: true,
            message: 'Mohon isi nama fakultas',
            trigger: 'blur'
        }
    ],
    email: [
        {
            required: true,
            message: 'Mohon isi email fakultas',
            trigger: 'blur'
        },
        {
            type: 'email',
            message: 'Mohon isi email yang valid',
            trigger: 'blur'
        }
    ],
    phone: [
        {
            required: true,
            message: 'Mohon isi nomor telepon fakultas',
            trigger: 'blur'
        }
    ],
    office: [
        {
            required: false,
            message: 'Mohon isi alamat kantor fakultas',
            trigger: 'blur'
        }
    ]
};

export const Major = {
    university_id: [
        {
            required: true,
            message: 'Mohon isi universitas',
            trigger: 'blur'
        }
    ],
    faculty_id: [
        {
            required: true,
            message: 'Mohon isi fakultas',
            trigger: 'blur'
        }
    ],
    major_name: [
        {
            required: true,
            message: 'Mohon isi nama prodi',
            trigger: 'blur'
        }
    ],
    email: [
        {
            required: true,
            message: 'Mohon isi email prodi',
            trigger: 'blur'
        },
        {
            type: 'email',
            message: 'Mohon isi email yang valid',
            trigger: 'blur'
        }
    ],
    phone: [
        {
            required: true,
            message: 'Mohon isi nomor telepon prodi',
            trigger: 'blur'
        }
    ],
    office: [
        {
            required: false,
            message: 'Mohon isi alamat kantor prodi',
            trigger: 'blur'
        }
    ]
};

export const Password = {
    password: [
        {
            required: true,
            message: 'Mohon isi password anda',
            trigger: 'blur'
        },
        {
            min: 8,
            message: 'Panjang huruf minimal 8 karakter',
            trigger: 'blur'
        }
    ],
    confirm_password: [
        {
            required: true,
            message: 'Mohon isi kembali password anda',
            trigger: 'blur'
        },
        {
            min: 8,
            message: 'Panjang huruf minimal 8 karakter',
            trigger: 'blur'
        }
    ]
};

export const CampusData = {
    role_id: [
        {
            required: false,
            message: 'Mohon isi role anda',
            trigger: 'blur'
        }
    ],
    nim: [
        {
            required: false,
            message: 'Mohon isi nim/nip anda',
            trigger: 'blur'
        }
    ],
    faculty_id: [
        {
            required: false,
            message: 'Mohon isi fakultas anda',
            trigger: 'blur'
        }
    ],
    major_id: [
        {
            required: false,
            message: 'Mohon isi jurusan anda',
            trigger: 'blur'
        }
    ],
    university_id: [
        {
            required: false,
            message: 'Mohon isi universitas anda',
            trigger: 'blur'
        }
    ],
    company_id: [
        {
            required: false,
            message: 'Mohon isi perusahaan anda',
            trigger: 'blur'
        }
    ],
    entry_year: [
        {
            required: false,
            message: 'Mohon isi tahun masuk/angkatan anda',
            trigger: 'blur'
        }
    ],
    class: [
        {
            required: false,
            message: 'Mohon isi kelas anda',
            trigger: 'blur'
        }
    ]
};

export const Class = {
    info: {
        class_name: [
            {
                required: true,
                message: 'Mohon isi nama kelas anda',
                trigger: 'blur'
            }
        ],
        description: [
            {
                required: false,
                message: 'Mohon isi deskripsi kelas anda',
                trigger: 'blur'
            }
        ],
        meeting_link: [
            {
                required: true,
                message: 'Mohon isi link meeting online kelas anda',
                trigger: 'blur'
            }
        ],
        location: [
            {
                required: false,
                message: 'Mohon isi lokasi kelas onsite anda',
                trigger: 'blur'
            }
        ]
    },
    lesson: {
        lesson_name: [
            {
                required: true,
                message: 'Mohon isi nama materi',
                trigger: 'blur'
            }
        ],
        lesson_attendance: [
            {
                required: false,
                message: 'Mohon isi komponen absensi',
                trigger: 'blur'
            }
        ],
        lesson_content: [
            {
                required: true,
                message: 'Mohon isi konten materi',
                trigger: 'blur'
            }
        ]
    }
};

export const Team = {
    team_name: [
        {
            required: true,
            message: 'Mohon isi nama team',
            trigger: 'blur'
        }
    ],
    company_id: [
        {
            required: true,
            message: 'Mohon isi perusahaan magang dari team ini',
            trigger: 'blur'
        }
    ],
    leader_id: [
        {
            required: true,
            message: 'Mohon isi ketua team',
            trigger: 'blur'
        }
    ],
    members: [
        {
            required: true,
            message: 'Mohon isi anggota dari team',
            trigger: 'blur'
        }
    ]
};

export const Project = {
    name: [
        {
            required: true,
            message: 'Mohon isi nama proyek anda',
            trigger: 'blur'
        }
    ],
    team_id: [
        {
            required: true,
            message: 'Mohon isi tim proyek anda',
            trigger: 'blur'
        }
    ],
    description: [
        {
            required: false,
            message: 'Mohon isi deskripsi proyek anda',
            trigger: 'blur'
        }
    ],
    project: [
        {
            required: false,
            message: 'Mohon isi file proyek anda',
            trigger: 'blur'
        }
    ]
};

export const Proposal = {
    title: [
        {
            required: true,
            message: 'Mohon isi judul proposal anda',
            trigger: 'blur'
        }
    ],
    team_id: [
        {
            required: true,
            message: 'Mohon isi tim proposal anda',
            trigger: 'blur'
        }
    ],
    content: [
        {
            required: true,
            message: 'Mohon isi konten proposal anda',
            trigger: 'blur'
        }
    ]
};

export const ProblemSolvingQuestionnaire = {
    s1_1: [
        {
            required: true,
            message: 'Mohon isi pertanyaan ini',
            trigger: 'blur'
        }
    ],
    s1_2: [
        {
            required: true,
            message: 'Mohon isi pertanyaan ini',
            trigger: 'blur'
        }
    ],
    s2_1: [
        {
            required: true,
            message: 'Mohon isi pertanyaan ini',
            trigger: 'blur'
        }
    ],
    s2_2: [
        {
            required: true,
            message: 'Mohon isi pertanyaan ini',
            trigger: 'blur'
        }
    ],
    s3_1: [
        {
            required: true,
            message: 'Mohon isi pertanyaan ini',
            trigger: 'blur'
        }
    ],
    s3_2: [
        {
            required: true,
            message: 'Mohon isi pertanyaan ini',
            trigger: 'blur'
        }
    ],
    s4_1: [
        {
            required: true,
            message: 'Mohon isi pertanyaan ini',
            trigger: 'blur'
        }
    ],
    s4_2: [
        {
            required: true,
            message: 'Mohon isi pertanyaan ini',
            trigger: 'blur'
        }
    ],
    s5_1: [
        {
            required: true,
            message: 'Mohon isi pertanyaan ini',
            trigger: 'blur'
        }
    ],
    s5_2: [
        {
            required: true,
            message: 'Mohon isi pertanyaan ini',
            trigger: 'blur'
        }
    ],
    s6_1: [
        {
            required: true,
            message: 'Mohon isi pertanyaan ini',
            trigger: 'blur'
        }
    ],
    s6_2: [
        {
            required: true,
            message: 'Mohon isi pertanyaan ini',
            trigger: 'blur'
        }
    ],
    s7_1: [
        {
            required: true,
            message: 'Mohon isi pertanyaan ini',
            trigger: 'blur'
        }
    ],
    s7_2: [
        {
            required: true,
            message: 'Mohon isi pertanyaan ini',
            trigger: 'blur'
        }
    ],
    s8_1: [
        {
            required: true,
            message: 'Mohon isi pertanyaan ini',
            trigger: 'blur'
        }
    ],
    s8_2: [
        {
            required: true,
            message: 'Mohon isi pertanyaan ini',
            trigger: 'blur'
        }
    ],
    s9_1: [
        {
            required: true,
            message: 'Mohon isi pertanyaan ini',
            trigger: 'blur'
        }
    ],
    s9_2: [
        {
            required: true,
            message: 'Mohon isi pertanyaan ini',
            trigger: 'blur'
        }
    ],
    s10_1: [
        {
            required: true,
            message: 'Mohon isi pertanyaan ini',
            trigger: 'blur'
        }
    ],
    s10_2: [
        {
            required: true,
            message: 'Mohon isi pertanyaan ini',
            trigger: 'blur'
        }
    ],
    s11_1: [
        {
            required: true,
            message: 'Mohon isi pertanyaan ini',
            trigger: 'blur'
        }
    ],
    s11_2: [
        {
            required: true,
            message: 'Mohon isi pertanyaan ini',
            trigger: 'blur'
        }
    ],
    s12_1: [
        {
            required: true,
            message: 'Mohon isi pertanyaan ini',
            trigger: 'blur'
        }
    ],
    s12_2: [
        {
            required: true,
            message: 'Mohon isi pertanyaan ini',
            trigger: 'blur'
        }
    ],
    s13_1: [
        {
            required: true,
            message: 'Mohon isi pertanyaan ini',
            trigger: 'blur'
        }
    ],
    s13_2: [
        {
            required: true,
            message: 'Mohon isi pertanyaan ini',
            trigger: 'blur'
        }
    ],
    s14_1: [
        {
            required: true,
            message: 'Mohon isi pertanyaan ini',
            trigger: 'blur'
        }
    ],
    s14_2: [
        {
            required: true,
            message: 'Mohon isi pertanyaan ini',
            trigger: 'blur'
        }
    ],
    s15_1: [
        {
            required: true,
            message: 'Mohon isi pertanyaan ini',
            trigger: 'blur'
        }
    ],
    s15_2: [
        {
            required: true,
            message: 'Mohon isi pertanyaan ini',
            trigger: 'blur'
        }
    ],
    s16_1: [
        {
            required: true,
            message: 'Mohon isi pertanyaan ini',
            trigger: 'blur'
        }
    ],
    s16_2: [
        {
            required: true,
            message: 'Mohon isi pertanyaan ini',
            trigger: 'blur'
        }
    ],
    s17_1: [
        {
            required: true,
            message: 'Mohon isi pertanyaan ini',
            trigger: 'blur'
        }
    ],
    s17_2: [
        {
            required: true,
            message: 'Mohon isi pertanyaan ini',
            trigger: 'blur'
        }
    ],
    s18_1: [
        {
            required: true,
            message: 'Mohon isi pertanyaan ini',
            trigger: 'blur'
        }
    ],
    s18_2: [
        {
            required: true,
            message: 'Mohon isi pertanyaan ini',
            trigger: 'blur'
        }
    ],
    s19_1: [
        {
            required: true,
            message: 'Mohon isi pertanyaan ini',
            trigger: 'blur'
        }
    ],
    s19_2: [
        {
            required: true,
            message: 'Mohon isi pertanyaan ini',
            trigger: 'blur'
        }
    ],
    s20_1: [
        {
            required: true,
            message: 'Mohon isi pertanyaan ini',
            trigger: 'blur'
        }
    ],
    s20_2: [
        {
            required: true,
            message: 'Mohon isi pertanyaan ini',
            trigger: 'blur'
        }
    ],
    s21_1: [
        {
            required: true,
            message: 'Mohon isi pertanyaan ini',
            trigger: 'blur'
        }
    ],
    s21_2: [
        {
            required: true,
            message: 'Mohon isi pertanyaan ini',
            trigger: 'blur'
        }
    ],
    s22_1: [
        {
            required: true,
            message: 'Mohon isi pertanyaan ini',
            trigger: 'blur'
        }
    ],
    s22_2: [
        {
            required: true,
            message: 'Mohon isi pertanyaan ini',
            trigger: 'blur'
        }
    ],
    s23_1: [
        {
            required: true,
            message: 'Mohon isi pertanyaan ini',
            trigger: 'blur'
        }
    ],
    s23_2: [
        {
            required: true,
            message: 'Mohon isi pertanyaan ini',
            trigger: 'blur'
        }
    ],
    s24_1: [
        {
            required: true,
            message: 'Mohon isi pertanyaan ini',
            trigger: 'blur'
        }
    ],
    s24_2: [
        {
            required: true,
            message: 'Mohon isi pertanyaan ini',
            trigger: 'blur'
        }
    ],
    s25_1: [
        {
            required: true,
            message: 'Mohon isi pertanyaan ini',
            trigger: 'blur'
        }
    ],
    s25_2: [
        {
            required: true,
            message: 'Mohon isi pertanyaan ini',
            trigger: 'blur'
        }
    ]
};

export const MotivationQuestionnaire = {
    stepOne: {
        gender: [
            {
                required: true,
                message: 'Mohon isi pertanyaan ini',
                trigger: 'blur'
            }
        ],
        suku_bangsa: [
            {
                required: true,
                message: 'Mohon isi pertanyaan ini',
                trigger: 'blur'
            }
        ],
        usia: [
            {
                required: true,
                message: 'Mohon isi pertanyaan ini',
                trigger: 'blur'
            }
        ],
        ipk: [
            {
                required: true,
                message: 'Mohon isi pertanyaan ini',
                trigger: 'blur'
            }
        ]
    },
    stepTwo: {
        imtk_1: [
            {
                required: true,
                message: 'Mohon isi pertanyaan ini',
                trigger: 'blur'
            }
        ],
        imtk_2: [
            {
                required: true,
                message: 'Mohon isi pertanyaan ini',
                trigger: 'blur'
            }
        ],
        emid_1: [
            {
                required: true,
                message: 'Mohon isi pertanyaan ini',
                trigger: 'blur'
            }
        ],
        imes_1: [
            {
                required: true,
                message: 'Mohon isi pertanyaan ini',
                trigger: 'blur'
            }
        ],
        imes_2: [
            {
                required: true,
                message: 'Mohon isi pertanyaan ini',
                trigger: 'blur'
            }
        ],
        imta_1: [
            {
                required: true,
                message: 'Mohon isi pertanyaan ini',
                trigger: 'blur'
            }
        ],
        imta_2: [
            {
                required: true,
                message: 'Mohon isi pertanyaan ini',
                trigger: 'blur'
            }
        ],
        imta_3: [
            {
                required: true,
                message: 'Mohon isi pertanyaan ini',
                trigger: 'blur'
            }
        ],
        emin_1: [
            {
                required: true,
                message: 'Mohon isi pertanyaan ini',
                trigger: 'blur'
            }
        ],
        emin_2: [
            {
                required: true,
                message: 'Mohon isi pertanyaan ini',
                trigger: 'blur'
            }
        ],
        emr_1: [
            {
                required: true,
                message: 'Mohon isi pertanyaan ini',
                trigger: 'blur'
            }
        ],
        emr_2: [
            {
                required: true,
                message: 'Mohon isi pertanyaan ini',
                trigger: 'blur'
            }
        ],
        emr_3: [
            {
                required: true,
                message: 'Mohon isi pertanyaan ini',
                trigger: 'blur'
            }
        ]
    }
};

export const PerceptionQuestionnaire = {
    s1: [
        {
            required: true,
            message: 'Mohon isi pertanyaan ini',
            trigger: 'blur'
        }
    ],
    s2: [
        {
            required: true,
            message: 'Mohon isi pertanyaan ini',
            trigger: 'blur'
        }
    ],
    s3: [
        {
            required: true,
            message: 'Mohon isi pertanyaan ini',
            trigger: 'blur'
        }
    ],
    s4: [
        {
            required: true,
            message: 'Mohon isi pertanyaan ini',
            trigger: 'blur'
        }
    ],
    s5: [
        {
            required: true,
            message: 'Mohon isi pertanyaan ini',
            trigger: 'blur'
        }
    ],
    s6: [
        {
            required: true,
            message: 'Mohon isi pertanyaan ini',
            trigger: 'blur'
        }
    ],
    s7: [
        {
            required: true,
            message: 'Mohon isi pertanyaan ini',
            trigger: 'blur'
        }
    ],
    s8: [
        {
            required: true,
            message: 'Mohon isi pertanyaan ini',
            trigger: 'blur'
        }
    ],
    s9: [
        {
            required: true,
            message: 'Mohon isi pertanyaan ini',
            trigger: 'blur'
        }
    ],
    s10: [
        {
            required: true,
            message: 'Mohon isi pertanyaan ini',
            trigger: 'blur'
        }
    ]
};

export const PeerReviewQuestionnaire = {
    file: [
        {
            required: true,
            message: 'Mohon upload file anda',
            trigger: 'blur'
        }
    ]
};

export const PeerReviewQuestion = {
    title: [
        {
            required: true,
            message: 'Mohon isi judul',
            trigger: 'blur'
        }
    ],
    description: [
        {
            required: false,
            message: 'Mohon isi deskripsi',
            trigger: 'blur'
        }
    ],
    file: [
        {
            required: true,
            message: 'Mohon upload file anda',
            trigger: 'blur'
        }
    ]
};

export const ThreeSixtyQuestionnaire = {
    stepOne: {
        team_id: [
            {
                required: true,
                message: 'Mohon pilih tim anda',
                trigger: 'blur'
            }
        ],
        peer_id: [
            {
                required: true,
                message: 'Mohon pilih nama rekan anda',
                trigger: 'blur'
            }
        ],
        matkul: [
            {
                required: true,
                message: 'Mohon isi mata kuliah',
                trigger: 'blur'
            }
        ]
    },
    stepTwo: {
        m1: [
            {
                required: true,
                message: 'Mohon isi pertanyaan ini',
                trigger: 'blur'
            }
        ],
        m2: [
            {
                required: true,
                message: 'Mohon isi pertanyaan ini',
                trigger: 'blur'
            }
        ],
        m3: [
            {
                required: true,
                message: 'Mohon isi pertanyaan ini',
                trigger: 'blur'
            }
        ]
    },
    stepThree: {
        ca1: [
            {
                required: true,
                message: 'Mohon isi pertanyaan ini',
                trigger: 'blur'
            }
        ],
        ca2: [
            {
                required: true,
                message: 'Mohon isi pertanyaan ini',
                trigger: 'blur'
            }
        ],
        ca3: [
            {
                required: true,
                message: 'Mohon isi pertanyaan ini',
                trigger: 'blur'
            }
        ]
    },
    stepFour: {
        co1: [
            {
                required: true,
                message: 'Mohon isi pertanyaan ini',
                trigger: 'blur'
            }
        ],
        co2: [
            {
                required: true,
                message: 'Mohon isi pertanyaan ini',
                trigger: 'blur'
            }
        ]
    },
    stepFive: {
        l1: [
            {
                required: true,
                message: 'Mohon isi pertanyaan ini',
                trigger: 'blur'
            }
        ],
        l2: [
            {
                required: true,
                message: 'Mohon isi pertanyaan ini',
                trigger: 'blur'
            }
        ],
        l3: [
            {
                required: true,
                message: 'Mohon isi pertanyaan ini',
                trigger: 'blur'
            }
        ],
        l4: [
            {
                required: true,
                message: 'Mohon isi pertanyaan ini',
                trigger: 'blur'
            }
        ],
        l5: [
            {
                required: true,
                message: 'Mohon isi pertanyaan ini',
                trigger: 'blur'
            }
        ]
    },
    stepSix: {
        e1: [
            {
                required: true,
                message: 'Mohon isi pertanyaan ini',
                trigger: 'blur'
            }
        ],
        e2: [
            {
                required: true,
                message: 'Mohon isi pertanyaan ini',
                trigger: 'blur'
            }
        ],
        e3: [
            {
                required: true,
                message: 'Mohon isi pertanyaan ini',
                trigger: 'blur'
            }
        ]
    },
    stepSeven: {
        p1: [
            {
                required: true,
                message: 'Mohon isi pertanyaan ini',
                trigger: 'blur'
            }
        ],
        p2: [
            {
                required: true,
                message: 'Mohon isi pertanyaan ini',
                trigger: 'blur'
            }
        ],
        p3: [
            {
                required: true,
                message: 'Mohon isi pertanyaan ini',
                trigger: 'blur'
            }
        ],
        p4: [
            {
                required: true,
                message: 'Mohon isi pertanyaan ini',
                trigger: 'blur'
            }
        ]
    }
};
