<template>
    <div class="text-center">
        <i class="bi bi-check-circle text-success" style="font-size: 72px"></i>
        <h4
            class="fw-bold fs-3"
            v-text="`${$t('registration')} ${$t('success')}`"
        ></h4>
        <p
            class="text-secondary"
            v-text="
                $t('form.register.successMsg', {
                    email:
                        auth.user != null && Object.keys(auth.user).length > 0
                            ? auth.user.email
                            : ''
                })
            "
        ></p>

        <EmailVerification hide-button />
    </div>
</template>

<script lang="ts">
import { defineComponent, computed, ComputedRef } from 'vue';
import { useStore } from 'vuex';

import { UserAuthInfo } from '@/interfaces/User';

import EmailVerification from '@/components/EmailVerification.vue';

export default defineComponent({
    name: 'RegisterPageStepTwo',
    components: { EmailVerification },
    setup(props: { email: string }) {
        const store = useStore();
        const auth: ComputedRef<UserAuthInfo> = computed(
            () => store.getters['getAuth']
        );

        return {
            auth
        };
    }
});
</script>
