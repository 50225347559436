import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { PaginationInterface } from '@/interfaces/Pagination';
import { initialPagination } from '@/core/helpers/default-pagination';
import ApiService from '@/core/services/ApiService';
import { FacultyInterface } from '@/interfaces/Faculty';
import { MajorInterface, UniversityInterface } from '@/interfaces/University';
import { Faculty } from '@/views/dashboard/faculties/_form/_type';
import { Major } from '@/views/dashboard/majors/_form/_type';

interface CampusInfo {
    universities: UniversityInterface[];
    allFaculties: {
        data: FacultyInterface[];
        meta: PaginationInterface;
    };
    faculties: {
        data: FacultyInterface[];
        meta: PaginationInterface;
    };
    allMajors: {
        data: MajorInterface[];
        meta: PaginationInterface;
    };
    majors: {
        data: MajorInterface[];
        meta: PaginationInterface;
    };
}

@Module
export default class CampusModule extends VuexModule implements CampusInfo {
    universities: UniversityInterface[] = [];
    allFaculties: { data: FacultyInterface[]; meta: PaginationInterface } = {
        data: [],
        meta: initialPagination
    };
    faculties: { data: FacultyInterface[]; meta: PaginationInterface } = {
        data: [],
        meta: initialPagination
    };
    allMajors: { data: MajorInterface[]; meta: PaginationInterface } = {
        data: [],
        meta: initialPagination
    };
    majors: { data: MajorInterface[]; meta: PaginationInterface } = {
        data: [],
        meta: initialPagination
    };

    /**
     * Get All Universities
     * @returns object
     */
    get getUniversities(): UniversityInterface[] {
        return this.universities;
    }

    /**
     * Get All Faculties
     * @returns object
     */
    get getAllFaculties(): {
        data: FacultyInterface[];
        meta: PaginationInterface;
    } {
        return this.allFaculties;
    }

    /**
     * Get Univeristy Faculties
     * @returns object
     */
    get getFaculties(): {
        data: FacultyInterface[];
        meta: PaginationInterface;
    } {
        return this.faculties;
    }

    /**
     * Get All Majors
     * @returns object
     */
    get getAllMajors(): {
        data: MajorInterface[];
        meta: PaginationInterface;
    } {
        return this.allMajors;
    }

    /**
     * Get Univeristy Majors
     * @returns object
     */
    get getMajors(): {
        data: MajorInterface[];
        meta: PaginationInterface;
    } {
        return this.majors;
    }

    @Mutation
    [Mutations.SET_UNIVERSITIES](payload: UniversityInterface[]): void {
        this.universities = payload;
    }

    @Mutation
    [Mutations.SET_ALL_FACULTIES](payload: {
        data: FacultyInterface[];
        meta: PaginationInterface;
    }): void {
        this.allFaculties.data = payload.data;
        this.allFaculties.meta = payload.meta;
    }

    @Mutation
    [Mutations.SET_FACULTIES](payload: {
        data: FacultyInterface[];
        meta: PaginationInterface;
    }): void {
        this.faculties.data = payload.data;
        this.faculties.meta = payload.meta;
    }

    @Mutation
    [Mutations.SET_FACULTY](payload: FacultyInterface): void {
        this.faculties.data = this.faculties.data.map((faculty) =>
            payload.id === faculty.id ? { ...faculty, ...payload } : faculty
        );
    }

    @Mutation
    [Mutations.SET_ALL_MAJORS](payload: {
        data: MajorInterface[];
        meta: PaginationInterface;
    }): void {
        this.allMajors.data = payload.data;
        this.allMajors.meta = payload.meta;
    }

    @Mutation
    [Mutations.SET_MAJORS](payload: {
        data: MajorInterface[];
        meta: PaginationInterface;
    }): void {
        this.majors.data = payload.data;
        this.majors.meta = payload.meta;
    }

    @Mutation
    [Mutations.SET_MAJOR](payload: MajorInterface): void {
        this.majors.data = this.majors.data.map((faculty) =>
            payload.id === faculty.id ? { ...faculty, ...payload } : faculty
        );
    }

    @Mutation
    [Mutations.REMOVE_FACULTY](id: number): void {
        this.faculties.data = this.faculties.data.filter(
            (faculty) => faculty.id !== id
        );
        this.faculties.meta.count -= 1;
    }

    @Mutation
    [Mutations.REMOVE_MAJOR](id: number): void {
        this.majors.data = this.majors.data.filter((major) => major.id !== id);
        this.majors.meta.count -= 1;
    }

    @Action({ rawError: true })
    [Actions.GET_ALL_FACULTY_LIST](params: any): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            ApiService.get(`/faculties`, params)
                .then(({ data }) => {
                    this.context.commit(Mutations.SET_ALL_FACULTIES, data.data);
                    resolve(data.data);
                })
                .catch((e) => {
                    reject(e);
                });
        });
    }

    @Action({ rawError: true })
    [Actions.GET_ALL_UNIVERSITY_LIST](query: any = {}): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            ApiService.get(`/universities`, query)
                .then(({ data }) => {
                    this.context.commit(Mutations.SET_UNIVERSITIES, data.data);
                    resolve(data.data);
                })
                .catch((e) => {
                    reject(e);
                });
        });
    }

    @Action({ rawError: true })
    [Actions.GET_ALL_MAJOR_LIST](params: any): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            ApiService.get(`/majors`, params)
                .then(({ data }) => {
                    this.context.commit(Mutations.SET_ALL_MAJORS, data.data);
                    resolve(data.data);
                })
                .catch((e) => {
                    reject(e);
                });
        });
    }

    @Action({ rawError: true })
    [Actions.GET_FACULTY_LIST](params: any): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            ApiService.get(
                `/universities/${params.university_id}/faculties`,
                params
            )
                .then(({ data }) => {
                    this.context.commit(Mutations.SET_FACULTIES, data.data);
                    resolve(data.data);
                })
                .catch((e) => {
                    reject(e);
                });
        });
    }

    @Action({ rawError: true })
    [Actions.GET_MAJOR_LIST](params: any): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            ApiService.get(`/faculties/${params.faculty_id}/majors`, params)
                .then(({ data }) => {
                    this.context.commit(Mutations.SET_MAJORS, data.data);
                    resolve(data.data);
                })
                .catch((e) => {
                    reject(e);
                });
        });
    }

    @Action({ rawError: true })
    [Actions.CREATE_FACULTY](payload: Faculty): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            ApiService.post(
                `/universities/${payload.university_id}/faculties`,
                {
                    ...payload,
                    university_id: undefined
                }
            )
                .then(({ data }) => {
                    this.context.commit(Mutations.ADD_FACULTY, data.data);
                    resolve(data.data);
                })
                .catch((e) => {
                    reject(e);
                });
        });
    }

    @Action({ rawError: true })
    [Actions.CREATE_MAJOR](payload: Major): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            ApiService.post(`/faculties/${payload.faculty_id}/majors`, {
                ...payload
            })
                .then(({ data }) => {
                    this.context.commit(Mutations.ADD_MAJOR, data.data);
                    resolve(data.data);
                })
                .catch((e) => {
                    reject(e);
                });
        });
    }

    @Action({ rawError: true })
    [Actions.UPDATE_FACULTY](payload: {
        data: FacultyInterface;
        university_id: number;
        faculty_id: number;
    }): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            ApiService.post(
                `/universities/${payload.university_id}/faculties/${payload.faculty_id}`,
                payload.data
            )
                .then(({ data }) => {
                    this.context.commit(Mutations.SET_FACULTY, data.data);
                    resolve(data.data);
                })
                .catch((e) => {
                    reject(e);
                });
        });
    }

    @Action({ rawError: true })
    [Actions.UPDATE_MAJOR](payload: {
        data: MajorInterface;
        faculty_id: number;
    }): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            ApiService.post(
                `/faculties/${payload.faculty_id}/majors`,
                payload.data
            )
                .then(({ data }) => {
                    this.context.commit(Mutations.SET_FACULTY, data.data);
                    resolve(data.data);
                })
                .catch((e) => {
                    reject(e);
                });
        });
    }

    @Action({ rawError: true })
    [Actions.DELETE_FACULTY](params: {
        university_id: number;
        id: number;
    }): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            ApiService.delete(
                `/universities/${params.university_id}/faculties/${params.id}`
            )
                .then(({ data }) => {
                    this.context.commit(Mutations.REMOVE_FACULTY, params.id);
                    resolve(data.data);
                })
                .catch((e) => {
                    reject(e);
                });
        });
    }

    @Action({ rawError: true })
    [Actions.DELETE_MAJOR](params: {
        faculty_id: number;
        id: number;
    }): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            ApiService.delete(
                `/faculties/${params.faculty_id}/majors/${params.id}`
            )
                .then(({ data }) => {
                    this.context.commit(Mutations.REMOVE_FACULTY, params.id);
                    resolve(data.data);
                })
                .catch((e) => {
                    reject(e);
                });
        });
    }
}
