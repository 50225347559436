import { useCookies } from 'vue3-cookies';

const ID_TOKEN_KEY = 'access_token' as string;
const { cookies } = useCookies();

/**
 * @description get token from cookies
 */
export const getToken = (): string | null => {
    return cookies.get(ID_TOKEN_KEY);
};

/**
 * @description save token into cookies
 * @param token: string
 */
export const setToken = (payload: {
    token: string;
    expires?: number;
}): void => {
    cookies.set(ID_TOKEN_KEY, payload.token, payload.expires);
};

/**
 * @description remove token form cookies
 */
export const destroyToken = (): void => {
    cookies.remove(ID_TOKEN_KEY);
};

export default { getToken, setToken, destroyToken };
